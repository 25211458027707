import { AnyAction, Dispatch } from 'redux';
import { EBillingPeriod } from 'ts/enums/billing.period';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const applyCoupon = (code: string, onSuccess = () => {}, onError: any = () => {}) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.APPLY_COUPON,
    payload: {
      method: 'POST',
      endpoint: `billing/code/redeem/${code}`,
    },
    onSuccess,
    onFailed: (error) => {
      const errorMessage = error?.response?.data?.message || 'Apply coupon error';
      onError(errorMessage);
    },
    components: ['apply_coupon'],
  };
  dispatch(action);
};

export const generatePurchaseUrl = (plan: string, billingPeriod: EBillingPeriod): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GENERATE_PURCHASE_URL,
    payload: {
      method: 'POST',
      endpoint: `billing/payment/purchase/url`,
      body: {
        plan,
        billingPeriod
      }
    },
    onSuccess: (url) => {
      window.open(url, '_blank', 'height=1200,width=520,scrollbars=yes')
    }
  };
  return action;
};
