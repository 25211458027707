const getIconLabel = (email?: string, firstName?: string, lastName?: string): string => {
    const letters = ['S','P'];
    if (firstName && lastName) {
      letters[0] = firstName[0];
      letters[1] = lastName[0];
    } else if (email) {
      letters[0] = email[0];
      letters[1] = email[1];
    }
    return letters.join('');
}

export default getIconLabel;