import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { ICompany } from 'ts/interfaces/company/company';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const autocompleteCompany = (query: string, excludedDomains: string[] = []): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.COMPANY_AUTOCOMPLETE,
    payload: {
      host: 'https://autocomplete.clearbit.com',
      method: 'GET',
      endpoint: `v1/companies/suggest?query=${query}`,
    },
    components: ['company_autocomplete'],
    skipAuth: true,
    forwardedData: {
      excludedDomains
    }
  };

  return action;
};

export const getCompaniesByDomain = (domains: string[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.LOAD_LEAD_COMPANIES,
    payload: {
      method: 'GET',
      endpoint: `companies`,
      params: {
        domains,
      }
    },
    components: ['lead_companies'],
  };

  return action;
};

export const setLoadedCompanies = (companies: ICompany[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_LEAD_COMPANIES,
    data: companies,
  };

  return action;
};
