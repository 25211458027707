import React from 'react';
import { useSelector } from 'react-redux';
import { modalsSelector } from 'data/selectors/modals';
import { EModalTypes } from 'ts/enums/modal.types';

import CreateBulkOperationModal from 'components/modals/createBulkOperationModal';
import ConfirmationModal from 'components/modals/confirmationModal';

const ModalsController = () => {
  const { type } = useSelector(modalsSelector);

  switch (type) {
    case EModalTypes.CREATE_BULK_OPERATIONS: {
      return <CreateBulkOperationModal />;
    }
    case EModalTypes.CONFIRMATION: {
      return <ConfirmationModal />
    }
    default: 
      return null;
  }
}

export default ModalsController;
