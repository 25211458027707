import thunk from 'redux-thunk';
import {
  createStore, applyMiddleware, Store, Dispatch
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import rootReducer from './reducers/rootReducer';
import createApiMiddleware from './middleware/api';
import createEventsMiddleware from './middleware/events';

const apiMiddleware = createApiMiddleware();
const eventsMiddleware = createEventsMiddleware();
export default function configureStore(initialState: any = {}): Store<any, IReduxAction> & {
  dispatch: Dispatch;
} {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunk,
        apiMiddleware,
        eventsMiddleware
      ),
    ),
  );
}