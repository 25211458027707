import { FC } from 'react';
import { useSelector } from 'react-redux';
import { drawerSelector } from 'data/selectors/drawer';
import { IDrawerState } from 'ts/interfaces/drawer/drawer';
import { EDrawerTypes } from 'ts/enums/drawer.types';

import LeadInfoSideBar from 'components/LeadInfoSideBar';

const DrawerController: FC = () => {
  const drawerState: IDrawerState = useSelector(drawerSelector);

  switch (drawerState.type) {
    case EDrawerTypes.LEAD_INFO: return <LeadInfoSideBar visible={drawerState.visible} lead={drawerState.data} />
    default: return null;
  }
}

export default DrawerController;