import { createSelector } from 'reselect';

const baseWizardSelector = (state: any) => state?.bulkOperationsWizard;

export const typeSelector = createSelector(
    baseWizardSelector,
    (state) => state?.type,
);

export const matchingSelector = createSelector(
    baseWizardSelector,
    (state) => state.matching
);

export const processingFileSelector = createSelector(
    baseWizardSelector,
    (state) => state?.processingFile,
);

export const processingFileHeadersSelector = createSelector(
    processingFileSelector,
    (file) => file?.headers || [],
);

export const processingFilePreviewSelector = createSelector(
    processingFileSelector,
    (file) => file?.preview || [],
);