import { createSelector } from 'reselect';

const baseUsageSelector = (state: any) => state?.usage;

export const usageAnalyticsSelector = createSelector(
  baseUsageSelector,
  (usage) => usage?.usageAnalytics || [],
);

export const usageHistorySelector = createSelector(
  baseUsageSelector,
  (usage) => usage?.usageHistory || [],
);

export const usageMetaSelector = createSelector(
  baseUsageSelector,
  (usage) => usage?.meta || {},
);
