import { Dispatch } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const initEventStream = () => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.INIT_EVENT_STREAM,
    event: {
        path: 'events/stream'
    }
  };
  dispatch(action);
};
