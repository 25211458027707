import { notification } from 'antd';
import { BsX } from 'react-icons/bs';
import { ErrorIcon, CloseButton, SuccessIcon } from './styles';
import { INotification } from 'ts/interfaces/common/notification';

const openNotification = ({ message, description, duration = 5, type, onClick = () => {} }: INotification) => {
  let icon;
  switch (type) {
    case 'error': {
      icon = <ErrorIcon />;
      break;
    }
    case 'success': {
      icon = <SuccessIcon />
      break;
    }
    default:
      break;
  }

  notification.open({
    style: {
      borderRadius: '10px',
    },
    message,
    description,
    onClick,
    duration,
    icon,
    closeIcon: <CloseButton><BsX /></CloseButton>,
  });
}

export default openNotification;
