import { FC } from 'react';
import { SectionTitle } from 'components/common/styles';
import EmailSearchInput from 'layouts/emailSearchInput';
import EmailSearchResult from 'layouts/emailSearchResult';

import {
  Container
} from './styles';

const SearchPage: FC = () => {
  return (
    <Container>
      <SectionTitle>Search</SectionTitle>
      <EmailSearchInput />
      <EmailSearchResult />
    </Container>
  )
}

export default SearchPage;