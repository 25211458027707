import { FC, useCallback, useState } from 'react';
import { Avatar, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { upperFirst } from '@antv/util';

//** Redux layer */
import { toggleDrawer } from 'data/actions/drawer';
import { updateLead } from 'data/actions/leads';

//** Components  */
import Button from 'components/common/button';
import EmailCell from 'components/emailCell';

//** Enums, constants, etc */
import { ILead } from 'ts/interfaces/leads/lead';
import getAvatarColor from 'helpers/getAvatarColor';
import { ICompany } from 'ts/interfaces/company/company';
import { titleCase } from '@antv/x6/lib/util/string/format';
import { getLoadingSelector } from 'data/selectors/loading';

//** Styles */
import { FlexRowContainer, FlexColumnContainer, TextArea, Description } from 'components/common/styles';
import {
  CompanyLabel,
  EmailsContainer,
  Group,
  Label,
} from './styles';

const LeadInfoSideBar: FC<{ visible: boolean, lead: ILead }> = ({ visible, lead }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingSelector('update_lead'));

  const [notes, setNotes] = useState<string | undefined>(lead?.notes);
  const [companies] = useState(lead.relations?.reduce((prev: ICompany[], curr) => { if (curr?.company) { prev.push(curr.company); } return prev; }, []));

  const saveDisabled = isLoading || notes === lead?.notes;

  const handleSave = useCallback(() => {
    const newData: ILead = {
      ...lead,
      notes,
    };
    
    dispatch(updateLead(
      lead._id as string,
      (Object.keys(newData) as Array<keyof ILead>).reduce((prev: any, curr) => {
        if (curr === 'relations') {
          const newCompanies = companies?.filter((company) => !lead[curr]?.find((relation) => relation.company?.domain === company.domain));
          newCompanies?.forEach((company) => newData[curr]?.push({ company: omit(company, 'logo') }));
          newData[curr] = newData[curr]?.map((relation) => {
            if (!companies?.find((company) => company.domain === relation.company?.domain)) relation.company = undefined;
            return relation;
          })
          prev[curr] = newData[curr];
        }
        else if (newData[curr] !== lead[curr]) prev[curr] = newData[curr];
        return prev;
      }, {}))
    )
  }, [dispatch, lead, notes, companies]);

  const handleClose = useCallback(() => {
    dispatch(toggleDrawer(false));
  }, [dispatch]);

  const Footer = () => (
    <div style={{ display: 'flex' }}>
      <Button disabled={saveDisabled} onClick={handleSave} style={{ width: 150, height: 40 }}>Save</Button>
      <Button onClick={handleClose} style={{ width: 120, height: 40, marginLeft: 10 }} empty>Close</Button>
    </div>
  )

  return (
    <Drawer
      title={'Lead info'}
      placement="right"
      width={window.innerWidth > 1249 ? 500 : '100%'}
      onClose={handleClose}
      open={visible}
      forceRender
      footer={<Footer />}
    >
        <>
          <Group>
            <FlexRowContainer style={{ gap: 10 }}>
              <Avatar size={65} style={{ backgroundColor: getAvatarColor(lead.firstName as string, lead.lastName as string), verticalAlign: 'middle', fontSize: 35 }}>
                {`${lead.firstName?.[0]}${lead.lastName?.[0]}`}
              </Avatar>
              <FlexColumnContainer style={{ flex: 1 }}>
                <span style={{ fontSize: 30, fontWeight: 600, marginTop: -8 }}>{`${lead.firstName} ${lead.lastName}`}</span>
                <EmailCell email={lead?.relations?.[0]?.email?.value} status={lead?.relations?.[0]?.email?.status} company={lead?.relations?.[0]?.company?.name} />
              </FlexColumnContainer>
            </FlexRowContainer>
          </Group>
          {(lead?.relations?.length || 1) > 1 && (
            <Group>
              <EmailsContainer>
                <Label>Emails</Label>
                {lead?.relations?.map((relation, index) => {
                  // todo: implement element of search start for relations with company and no email
                  if (relation?.email) return <EmailCell key={`email-cell-item-${index + 1}`} email={relation?.email?.value} status={relation?.email?.status} company={relation?.company?.name}/>
                  return null
                })}
              </EmailsContainer>
            </Group>
          )}
          <Group>
            {(Object.keys(omit(lead, '_id', 'user', 'createdAt', 'updatedAt', 'firstName', 'lastName', 'notes', 'relations', 'deleted')) as Array<keyof ILead>).map((key, index) => (
              <>
                {!!lead[key]?.length && (
                  <div key={`property-item-${key}-{index + 1}`}>
                    <Label>{upperFirst(key)}</Label>
                    <Description>{key === 'source' ? titleCase(lead[key] || '') : lead[key]}</Description>
                  </div>
                )}
              </>
            ))}
          </Group>
            <Group>
              <FlexColumnContainer>
                <Label>Companies</Label>
                <FlexRowContainer style={{ justifyContent: 'flex-start', gap: 10, marginTop: 10, flexWrap: 'wrap' }}>
                  {!!companies?.length && companies.map((company: any, index) => (
                    <CompanyLabel key={`${company}-${index+1}`}>
                      <span>{upperFirst(company || '')}</span>
                    </CompanyLabel>  
                  ))}
                </FlexRowContainer>
              </FlexColumnContainer>
            </Group>
          <Group>
            <Label>Notes</Label>
            <TextArea
              value={notes}
              placeholder='Additional info about the lead...'
              onChange={(e) => setNotes(e.target.value)}
              style={{ height: 150, marginTop: 10 }}
            />
          </Group>
        </>
    </Drawer>
  )
};

export default LeadInfoSideBar;