import styled from "styled-components";
import { Menu, Dropdown, } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`;

export const CustomDropdown = styled(Dropdown)`
  border: none;
  box-shadow: none;
  border-radius: 4px;

  &:hover, &:focus {
    color: inherit;
    background: rgb(245, 247, 248);
    transition: all 0.2s ease-in-out 0s;
  }
`;

export const CaretDown = styled(CaretDownOutlined)`
  color: #c5c9cf;
  svg {
    width: auto;
    height: 12px;
  }
`;

export const MenuItem = styled(Menu.Item)`
    border-top: 1px solid #e6eaee;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
    cursor: pointer;
    width: 270px;

    @media (max-width: 1249px) {
      width: 100vw;
    }

    span {
      display: flex;
      align-items: center;
    }

    &:hover {
      background: rgb(245,247,248);
    }

    svg {
      margin-right: 10px;
      color: rgb(139, 139, 139);
      font-size: 16px;
    }
`;

export const UpgradePlanContainer = styled.div`
  border-top: 1px solid #e6eaee;
  padding: 10px 15px;
`;

export const UpgradePlanLink = styled(Menu.Item)`
  color: #5766EB;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms ease;
  border-radius: 4px;
  
  &:active {
    transform: scale(0.9);
  }

  svg {
    margin-right: 3px;
  }

  background: rgb(245,247,248);

  &:hover {
    opacity: 0.7;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
`;

export const UserIconContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #5766ec;
    justify-content: center;
    border-radius: 50%;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
`;

export const Email = styled.span`
  margin-top: 5px;
`;