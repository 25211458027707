import React, { FC, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usageHistorySelector, usageMetaSelector } from 'data/selectors/usage';
import { getUsageHistory } from 'data/actions/usage';
import Table from 'components/commonTable';
import Pagination from 'components/pagination';

import { ControlsBarContainer } from './styles';
import HistoryTableColumns from './columns';

const UsageTable: FC<{ period: string, type: string }> = ({ period, type }) => {
  const dispatch = useDispatch();
  const history = useSelector(usageHistorySelector);
  const { totalItems = 0, totalPages = 0 } = useSelector(usageMetaSelector);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(10);

  useEffect(() => {
    dispatch(getUsageHistory({ pageNumber, pageSize }, period, type))
  }, [dispatch, pageNumber, pageSize, period, type]);

  const columns = useMemo(
    () => HistoryTableColumns, []
  );

  if (!history.length) return null;

  return (
    <>
      <ControlsBarContainer>
        <Pagination
          totalItems={totalItems}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePage={setPageNumber}
          totalPages={totalPages}
        />
      </ControlsBarContainer>
      <Table columns={columns} data={history} containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }} />
    </>
  );
};

export default UsageTable;
