import { FC, ReactNode } from 'react';
import { Container } from './styles';

const ContentBox: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container>
      { children }
     </Container>
  )
}

export default ContentBox;