import React, { FC, ReactNode } from 'react';
import Header from 'components/header';
import { Container, ContentContainer } from './styles';

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Container>
            <Header />
            <ContentContainer>
                {children}
            </ContentContainer>
        </Container>
    )
}

export default MainLayout;