import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from 'layouts/auth/authLayout';
import EmailInput from 'components/common/emailInput';
import PasswordInput from 'components/common/passwordInput';
import { ForgotPassword, Label, CreateAccountLabel, Title, ErrorMessage } from './styles';
import Button from 'components/common/button';
import { signIn } from 'data/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadingSelector } from 'data/selectors/loading';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { FcGoogle } from 'react-icons/fc';

const SignInPage: FC = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const loading = useSelector(getLoadingSelector('sign_in'));
    const google_loading = useSelector<boolean>(getLoadingSelector('google_auth'));

    const handleSignIn = (e: any) => {
        e.preventDefault();
        dispatch(signIn(email, password, setError));
    };

    const connectGmailAccount = (e: any) => {
      e.preventDefault();
      const url = buildOauthRequestUrl();
      window.location.replace(url);
    };

    return (
        <AuthLayout>
            <Title>Sign In</Title>
            <EmailInput value={email} onChange={(e: any) => setEmail(e?.target?.value)} />
            <PasswordInput value={password} onChange={(e: any) => setPassword(e?.target?.value)}/>
            <ForgotPassword><Link to="/password-forgot">Forgot password?</Link></ForgotPassword>
            { error && <ErrorMessage>{error}</ErrorMessage> }
            <Button onClick={handleSignIn} loading={loading}>Sign in</Button>
            <Label>or</Label>
            <Button onClick={connectGmailAccount} loading={google_loading} empty><FcGoogle size={18} style={{ marginRight: 5 }}/>Sign in with Google</Button>
            <CreateAccountLabel><Link to="/sign-up">Don't have an account? Create!</Link></CreateAccountLabel>
        </AuthLayout>
    )
} 

export default SignInPage;
