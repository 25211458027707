import styled, { keyframes } from "styled-components";
import { fadeIn } from 'react-animations';
import { FlexRowContainer } from 'components/common/styles';

const fadeInAnimation = keyframes`${fadeIn}`;
export const PlanListContainer = styled(FlexRowContainer)`

  @media (max-width: 1320px) {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
`;

export const PlanContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${props => props.active ? '#5766ec;' : 'white'};
  color: ${props => props.active ? 'white' : 'auto'};
  border: 1px solid #ECECEC;
  box-shadow: rgb(124 124 149 / 8%) 0px 8px 30px;
  border-radius: 8px;
  padding: 30px;
  margin: 0 10px;
  animation: 100ms ${fadeInAnimation};
    
  svg {
    color: ${props => props.active ? 'white' : '#5766ec'};
  }
`;

export const PlanLabel = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

export const PlanPricingWrapper = styled.div`
  margin-top: 10px;
`;

export const PlanPrice = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const FeaturesContainer = styled.ul`
  margin-top: 10px;
  margin-bottom: 0;
`;

export const FeatureItem = styled.li`
  margin: 10px 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 50px 0;
`;

export const BillingCycleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  font-weight: bold;

  button {
    margin: 0 10px;
  }
`;

export const ActiveButtonStyles = {
  width: 250,
  background: 'white',
  border: 'none',
  pointerEvents: 'none',
  fontWeight: 'bold',
  textTransform: 'uppercase'
}

export const ButtonStyles = {
  width: 250,
}