import { FC, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { EModalTypes } from 'ts/enums/modal.types';
import { 
    StepContainer,
    ButtonContainer,
} from './styles';
import {
  Header,
  Footer,
  ControlButton,
  Container,
  Title,
} from '../baseModal/styles';
import { modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import Steps from 'components/steps';
import { createBulkOperation } from 'data/actions/bulkOperations';
import { flushWizardState } from 'data/actions/bulkOperationsWizard';
import { typeSelector, matchingSelector, processingFileSelector } from 'data/selectors/bulkOperationsWizard';
import { EBulkOperationType } from 'ts/enums/bulkOperation';
import { matchingColumns } from 'ts/constants/bulkOperation';
import { BsArrowLeftShort, BsX } from 'react-icons/bs';

import TypeStep from './steps/type';
import FileStep from './steps/file';
import ColumnsMatchingStep from './steps/columnsMatching';

const StepController = [
    {
        name: 'Type',
        component: <TypeStep />,
        title: 'Select bulk operation type'
    },
    {
        name: 'File',
        component: <FileStep />,
        title: 'Upload your file'
    },
    {
        name: 'Columns',
        component: <ColumnsMatchingStep />,
        title: 'Match the columns in your file'
    }
]

const CreateBulkOperationModal: FC = () => {
    const [step, setStep] = useState(0);
    const dispatch = useDispatch();

    const { visible } = useSelector(modalsSelector);

    const type = useSelector(typeSelector) as EBulkOperationType;
    const matching = useSelector(matchingSelector);
    const processingFile = useSelector(processingFileSelector);

    const allowNextStep = useMemo(() => {
        const requiredColumns = matchingColumns?.[type];
        return (step === 0 && type) || 
        (step === 1 && processingFile) || 
        (step === 2 && requiredColumns.every(column => matching?.[column]));
    }, [step, type, matching, processingFile]);

    const isLastStep = step === StepController.length -1;

    const closeWizard = () => {
        dispatch(toggleModal(EModalTypes.CREATE_BULK_OPERATIONS, false))
        dispatch(flushWizardState());
        setStep(0);
    }

    return (
        <Modal 
            open={visible} 
            footer={null} 
            width={800} 
            style={{borderRadius: 8}} 
            onCancel={closeWizard}
            closable={false}
            destroyOnClose={true}
        >
            <Header>
                <ControlButton onClick={closeWizard}>
                    <BsX />
                </ControlButton>
                {
                    (step > 0) && (
                        <ControlButton onClick={() => setStep(step-1)}>
                            <BsArrowLeftShort />
                        </ControlButton>
                    )
                }
                
            </Header>
            <Container>
                <Title>{StepController?.[step]?.title}</Title>
            <StepContainer>
                { StepController?.[step]?.component }
            </StepContainer>
            
            <Footer> 
               <Steps steps={StepController.map(item => item.name)} currentStep={(isLastStep && allowNextStep) ? StepController.length : step}/>
                <ButtonContainer>
                    <Button 
                        disabled={!allowNextStep} 
                        size={ButtonHeight.LARGE} 
                        style={{ borderRadius: 30 }} 
                        onClick={() => {
                            if (isLastStep && allowNextStep) {
                                dispatch(createBulkOperation(type, processingFile._id, matching));
                                closeWizard();
                            } else {
                                setStep(step+1)
                            }
                        }}>
                            {isLastStep ? "Launch" : "Next step"}
                    </Button>
                </ButtonContainer>
            </Footer>
            </Container>
        </Modal>
    )
}

export default CreateBulkOperationModal;