import { IApplicationState, IApplication } from 'ts/interfaces/application/application';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IApplicationState = {
  data: []
};

const applicationReducer = (state = initialState, action: IReduxAction): IApplicationState | {} => {
  switch (action.type) {
    case EReduxTypes.GET_APPLICATIONS_LIST: {
      return {
        ...state,
        data: action?.payload || [],
      }
    }
    case EReduxTypes.ADD_NEW_APPLICATION: {
      const { data } = state;
      data.unshift(action.payload as IApplication);
      return {
        ...state,
        data: [...data]
      }
    }
    case EReduxTypes.DELETE_APPLICATION: {
      const { applicationId } = action.forwardedData;
      if (applicationId) {
        const data = state?.data?.filter((item: IApplication) => item._id !== applicationId);
        return {
          ...state,
          data: [...data]
        }
      }
      return state;
    }
    default:
      return state;
  }
};

export default applicationReducer;