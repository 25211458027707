import { FC, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadingSelector } from 'data/selectors/loading';
import VerificationPage from 'pages/verification';
import SearchPage from 'pages/search';
import MainLayout from 'layouts/main';
import LeadsPage from 'pages/leads';
import BulkOperationsPage from 'pages/bulkOperations';
import SettingsPage from 'pages//settings';
import AccountSettingsPage from 'pages/settings/account';
import PasswordSettingsPage from 'pages/settings/password';
import UsageSettingsPage from 'pages/settings/usage';
import BillingSettingsPage from 'pages/settings/billing';
import { initEventStream } from 'data/actions/events';
import { getUser, getUserSettings } from 'data/actions/user';
import FullScreenLoader from 'components/fullScreenLoader';
import ModalsController from 'components/modalsController';
import { getUserSubscription } from 'data/actions/subscriptions';
import SubscriptionPage from 'pages/settings/subscription';
import ApiSettingsPage from 'pages/settings/api';
import PricingPage from 'pages/pricing';

const AuthorizedUser: FC = () => {
  const dispatch = useDispatch();

    useEffect(() => {
      dispatch(initEventStream());
      dispatch(getUser());
      dispatch(getUserSubscription());
      dispatch(getUserSettings());
    }, [dispatch]);

    const loading = useSelector(getLoadingSelector('get_user'));

    if (loading) {
      return <FullScreenLoader />
    }

    return (
        <MainLayout>
          <Routes>
            <Route path="/search" element={<SearchPage />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route path="/leads" element={<LeadsPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/bulk-operations" element={<BulkOperationsPage />} />

            <Route path="/settings/api" element={<SettingsPage><ApiSettingsPage /></SettingsPage>} />
            <Route path="/settings/usage" element={<SettingsPage><UsageSettingsPage /></SettingsPage>} />
            <Route path="/settings/account" element={<SettingsPage><AccountSettingsPage /></SettingsPage>} />
            <Route path="/settings/billing" element={<SettingsPage><BillingSettingsPage /></SettingsPage>} />
            <Route path="/settings/password" element={<SettingsPage><PasswordSettingsPage /></SettingsPage>} />
            <Route path="/settings/subscription" element={ <SettingsPage><SubscriptionPage /></SettingsPage>} />
            <Route path="*" element={<Navigate to="/search" />} />
          </Routes>
          <ModalsController />
        </MainLayout>
    )
};

export default AuthorizedUser;