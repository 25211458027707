import moment from 'moment-timezone';
import {
  IUsageAnalyticsDocument,
  IUsageAnalytics,
} from 'ts/interfaces/usage/usage.analytics';

const DAYS_IN_MONTH = 30;
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

export const generateAnalytics = (
  dataset: IUsageAnalyticsDocument[],
  period: string,
  timezone = 'UTC',
): IUsageAnalytics[] => {
  const data: any = [];

  switch (period) {
    case 'month': {
      for (let i = 1; i <= DAYS_IN_MONTH; i++) {
        const date = moment()
          .tz(timezone)
          .subtract(DAYS_IN_MONTH, 'days')
          .add(i, 'days');
        const month = +date.format('M');
        const day = +date.format('D');
        
        const analytics: IUsageAnalyticsDocument[] = dataset.filter(({ _id }) => _id.month === month && _id.day === day);

        if (analytics.length > 0) {
          analytics.forEach(({ credits, _id }) => data.push({
            date: date.format('MMMM, D'),
            credits,
          }))
        } else {
          data.push({
            date: date.format('MMMM, D'),
          });
        }
      }
      break;
    }
    case 'day': {
      for (let i = 1; i <= HOURS_IN_DAY; i++) {
        const date = moment()
          .tz(timezone)
          .subtract(HOURS_IN_DAY, 'hour')
          .add(i, 'hour');
        const month = +date.format('M');
        const day = +date.format('D');
        const hour = +date.format('H');
        const analytics: IUsageAnalyticsDocument[] = dataset.filter(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour);
        if (analytics.length > 0) {
          analytics.forEach(({ credits, _id }) => data.push({
            date: date.format('HH:00'),
            credits,
          }))
        } else {
          data.push({
            date: date.format('HH:00'),
          });
        }
      }
      break;
    }
    case 'hour': {
      for (let i = 1; i <= MINUTES_IN_HOUR; i++) {
        const date = moment()
          .tz(timezone)
          .subtract(MINUTES_IN_HOUR, 'minute')
          .add(i, 'minute');
        const month = +date.format('M');
        const day = +date.format('D');
        const hour = +date.format('H');
        const minute = +date.format('mm');
        const analytics: IUsageAnalyticsDocument[] = dataset.filter(({ _id }) => _id.month === month && _id.day === day && _id.hour === hour && _id.minute === minute);
        if (analytics.length > 0) {
          analytics.forEach(({ credits, _id }) => data.push({
            date: date.format('HH:mm'),
            credits,
          }))
        } else {
          data.push({
            date: date.format('HH:mm'),
          });
        }
      }
      break;
    }
    default:
      break;
  }

  return data;
};

export const options: any = {
  isStack: true,
  xField: 'date',
  yField: 'credits',
  padding: 'auto',
  meta: {
    credits: {
      tickInterval: 5,
      minTickInterval: 1,
      nice: true,
    },
  },
  tooltip: {
    formatter: (data: { date: string, credits: number }) => {
      return { name: 'Credits', value: data.credits || 0, };
    },
  }
};
