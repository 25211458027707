import styled from 'styled-components';
import { INavItemProps } from 'ts/interfaces/components/nav.item.props';

export const Container = styled.header`
    width: 100%;
    height: 65px;
    padding: 0px 55px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECECEC;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(236, 236, 236);

    @media (max-width: 1249px) {
      padding: 0px 20px;
    }
`;

export const NavItemsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: space-between;
`;

export const NavItem = styled.li<INavItemProps>`
    list-style-type: none;
    margin: 0 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px 10px;
    will-change: transform;
    transition: transform 200ms ease;
    display: flex;
    align-items: center;

    ${(props) => props.active && 'background: rgb(238,245,254);'}
    ${(props) => props.active && 'color: rgb(87,102,236);'}
    ${(props) => props.active && 'box-sizing: border-box;'}

    &:hover {
        background: rgb(245, 247, 248);
    }

    &:active {
      transform: scale(0.9);
    }
`;

export const Logo = styled.img`
    width: 150px;
    margin-right: 30px;
    cursor: pointer;
    transition: transform 200ms ease;

    &:active {
      transform: scale(0.9);
    }
`; 

export const ProfileBarContainer = styled.div`
  display: flex;
`;

export const RefContainer = styled.div<{ ref: any }>`
  width: 100%;
`;

export const DesktopContainer = styled.div`
  display: flex;
  
  @media (max-width: 1249px) {
    display: none;
  }
`;

export const MobileMenuContainer = styled.div`
  font-size: 25px;
  display: none;
  align-items: center;
  

  @media (max-width: 1249px) {
    display: flex;
  }
`;

export const MobileMenuButtonContainer = styled.div`
  display: flex;
  margin-left: 5px;

  &:hover, &:focus {
    color: inherit;
    background: rgb(245, 247, 248);
    transition: all 0.2s ease-in-out 0s;
  }
`;
