import { EBulkOperationType } from 'ts/enums/bulkOperation'; 
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IBulkOperationsWizardState } from 'ts/interfaces/bulkOperations/bulkOperationWizard.state';
import { IProcessingFile } from 'ts/interfaces/bulkOperations/processingFile';

let initialState: IBulkOperationsWizardState = {
  type: EBulkOperationType.EMAIL_SEARCH,
  matching: {},
};

const bulkOperationsWizardReducer = (state = initialState || {}, action: IReduxAction): IBulkOperationsWizardState => {
  switch (action.type) {
    case EReduxTypes.BULK_OPERATION_WIZARD_SET_TYPE: {
      const type = action?.data?.type;
      return { ...state, type };
    }
    case EReduxTypes.BULK_OPERATION_CREATE_PROCESSING_FILE: {
      const processingFile = action.payload as IProcessingFile;
      return { ...state, processingFile, matching: {} };
    }
    case EReduxTypes.BULK_OPERATION_MATCH_COLUMN: {
      const column = action?.data?.column;
      const header = action?.data?.header;
      const matching = { ...state.matching };
      matching[column] = header;
      return { ...state, matching };
    }
    case EReduxTypes.BULK_OPERATION_WIZARD_FLUSH_STATE: {
      return initialState;
    }
    case EReduxTypes.BULK_OPERATION_CLEAR_COLUMN_MATCHING: {
      const column = action?.data?.column;
      const matching = { ...state.matching };
      delete matching[column];
      return {
        ...state,
        matching
      }
    }
    default:
      return state;
  }
};

export default bulkOperationsWizardReducer;