import styled from "styled-components";

export const Container = styled.div``;

export const DigitNumber = styled.input`
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: 1px solid rgb(230, 234, 238);
    border-right-width: 0.5px;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 14px 13px 13px 14px;
    transition: all 1s ease 0s;
    box-sizing: border-box;
    text-align: center;
    margin: 0 2px;

    &:hover, &:active, &:focus  {
      border: 1px solid rgb(87, 102, 236);
      outline: rgb(87, 102, 236);
      transition: all 0.2s ease-in-out 0s;
    }

    &:disabled {
      background: rgb(245, 247, 248);
      transition: all 1s ease 0s;
    }

`;