import { ILoadingState } from 'ts/interfaces/loading/loading';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ILoadingState = {
  verification: false,
  company_autocomplete: false,
  search_email: false,
  sign_up: false,
  confirm_account: false,
  sign_in: false,
  password_reset: false,
  password_forgot: false,
  bulk_operations_list: true,
  create_processing_file: false,
  google_auth: false,
  get_user: false,
  update_user: false,
  apply_coupon: false,
  applications: true,
  lead_companies: true,
  update_lead: false,
};
  
 const loadingReducer = (state = initialState, action: IReduxAction) => {
    switch (action.type) {
      case EReduxTypes.UPDATE_LOADING_COMPONENT: {
        const { components, loading } = action.data;
  
        components.forEach((item: keyof ILoadingState) => {
          state[item] = loading;
        });
  
        return {
          ...state,
        };
      }
  
      default:
        return state;
    }
};

export default loadingReducer;
