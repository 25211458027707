import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  border: 1px solid #ECECEC;
  padding: 20px;
  position: relative;
  background: white;
  box-shadow: rgb(124 124 149 / 8%) 0px 8px 30px;
  border-radius: 8px;
  margin-top: 20px;
  max-height: 100%;
`;