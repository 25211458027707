import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
`;

export const CodeBox = styled.div`
    background: #f7f8fa;
    overflow: hidden;
    border-radius: 0.3em;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #dfe2e5;
    cursor: pointer;
    width: 100%;
`;

export const Code = styled.code`
    color: #071427f2;
    font-family: Consolas,Monaco,"Andale Mono","Ubuntu Mono",monospace;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;