import React, { FC, ReactNode } from 'react';
import { Menu, MobileLinkContainer } from './styles';


const MobileMenu: FC<{ visible: boolean, children: ReactNode }> = ({ visible, children }) => (
  <Menu visible={visible}>
    <MobileLinkContainer>
      {children}
    </MobileLinkContainer>
  </Menu>
);

export default MobileMenu;