import styled from "styled-components";

export const EmailContainer = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;

span {
    margin-right: 10px;
    line-height: 1px;
}
`;
