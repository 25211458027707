import { FC, useState } from 'react';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { getBulkOperationResult, deleteBulkOperation, startBulkOperation } from 'data/actions/bulkOperations';
import { IBulkOperationProcessingStatus } from 'ts/interfaces/bulkOperations/bulkOperation';
import { EBulkOperationStatus } from 'ts/enums/bulkOperation';
import MoreIcon from 'assets/more.svg';
import { BiDownload } from 'react-icons/bi';
import { BsArrowRepeat, BsTrash } from 'react-icons/bs';
import { MenuContainer, MenuItem, MoreButtonContainer } from './styles';

const ActionBarMenu: FC<{ bulkOperationId: string, processingStatus?: IBulkOperationProcessingStatus, closeDropdown: () => void }> = ({ bulkOperationId, processingStatus, closeDropdown }) => {
  const dispatch = useDispatch();

  return (
    <MenuContainer>
      <MenuItem
        disabled={processingStatus?.status !== EBulkOperationStatus.DONE }
        key="download_result" 
        onClick={() => {
          dispatch(getBulkOperationResult(bulkOperationId));
          closeDropdown();
        }}
      >
        <BiDownload /> <span> Download result </span>
      </MenuItem>
      <MenuItem key="repeat_bulk_operation" onClick={() => {
        dispatch(startBulkOperation(bulkOperationId));
        closeDropdown();
      }}>
        <BsArrowRepeat />
        <span>Repeat</span>
      </MenuItem>
      <MenuItem key="delete_bulk_operation" onClick={() => {
        dispatch(deleteBulkOperation(bulkOperationId));
        closeDropdown();
      }}>
          <BsTrash />
          <span>Delete</span>
        </MenuItem>
    </MenuContainer>
  );
}

const ActionBar: FC<{ bulkOperationId: string, processingStatus?: IBulkOperationProcessingStatus }> = ({ bulkOperationId, processingStatus }) => {
    const [visible, setVisible] = useState(false);
    return (
        <Dropdown
          open={visible}
          overlay={<ActionBarMenu bulkOperationId={bulkOperationId} processingStatus={processingStatus} closeDropdown={() => setVisible(false)}/>} 
          trigger={['click']} 
          placement="bottomLeft"
          onOpenChange={(state) => setVisible(state)}
        >
            <div style={{ height: 35, width: 35 }}>
                <MoreButtonContainer src={MoreIcon} />
            </div>
        </Dropdown>
    )
}

export default ActionBar;