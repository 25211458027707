import styled from 'styled-components';

export const Menu = styled.div<{ visible: boolean }>`
  flex-direction: column;
  width: 100%;
  position: fixed;
  left: 0;
  top: 65px;
  background: #fff;
  z-index: 1000;
  left: ${(props) => props.visible ? 0 : 100}%;
  transition: .2s ease-in-out;
`;

export const MobileLinkContainer = styled.ul`
  padding-top: 20px;
`;

export const NavItem = styled.li`
    list-style-type: none;
    margin: 0 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px 10px;
    will-change: transform;
    transition: transform 200ms ease;

    &:hover {
        background: rgb(245, 247, 248);
    }

    &:active {
      transform: scale(0.9);
    }
`;
