import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import { ButtonHeight, } from 'ts/enums/styles';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { Container } from './styles';

const UpgradePlanButton: FC = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Button 
                size={ButtonHeight.MEDIUM}
                onClick={() => navigate('/pricing', { replace: true })}
            >
            <BsFillLightningChargeFill  color='white' style={{marginRight: 5}} />
                Upgrade plan
            </Button>
        </Container>
    )
}

export default UpgradePlanButton;
