import {
  FC,
} from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import {
  Container,
  Title,
  ButtonContainer,
  Description,
} from './styles';
import { searchLeads } from 'data/actions/leads';

const LeadsSearchEmptyState: FC<{ search: string, setSearch: unknown }> = ({ search, setSearch }: { search: string, setSearch: unknown }) => {
  const dispatch = useDispatch()
  const handleResetSearch = () => {
    dispatch(dispatch(searchLeads({ pageNumber: 0, pageSize: 10 }, '')))
    if (typeof setSearch === 'function') setSearch('')
  }

  return (
    <Container>
      <Title>Not Found</Title>
      <Description>
        Leads by search value
        <b>{` "${search}" `}</b>
        is not found
      </Description>
      <ButtonContainer>
        <Button size={ButtonHeight.MEDIUM} onClick={handleResetSearch}> 
          Reset search
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default LeadsSearchEmptyState;