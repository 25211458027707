import { FC } from 'react';
import { TableProps } from 'ts/types/table.props';
import { useTable } from 'react-table';
import { TableContainer } from './styles';

const Table: FC<TableProps> = ({ columns, data, containerStyles }) => {
    const {
      getTableProps,
      getTableBodyProps,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    return (
      <>
      
      <TableContainer style={containerStyles}>
      <table {...getTableProps()}>
       
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      </TableContainer>
      </>
    )
}

export default Table;