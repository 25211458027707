import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { googleAuth } from 'data/actions/user';

import FullScreenLoader from 'components/fullScreenLoader';

const GoogleVerifyPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const parsedLocation = queryString.parse(location.hash);
    if (parsedLocation?.id_token) {
      const token = parsedLocation.id_token as string;
      dispatch(googleAuth(token, (err: string)=>{ navigate(`/sign-in?error=${err}`) }))
    }
        
    }, [location, dispatch, navigate]);
    return (
      <FullScreenLoader />
    )
}

export default GoogleVerifyPage;