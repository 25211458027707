import { FC } from 'react';
import { PaginationProps } from 'ts/types/pagination.props';
import ChevronIcon from 'assets/chevron.svg';

import { PaginationContainer, PaginationButton } from './styles';

const Pagination: FC<PaginationProps> = ({
  pageNumber = 0,
  pageSize = 10,
  totalItems = 0,
  totalPages = 0,
  onChangePage = () => {},
}) => {
  const handleNextPage = () => onChangePage(pageNumber + 1);
  const handlePrevPage = () => onChangePage(pageNumber - 1 < 0 ? 0 : pageNumber - 1);

  if (totalItems <= pageSize) return null
  return (
    <PaginationContainer>
      {
        (pageNumber !==0) ? (
          <PaginationButton onClick={handlePrevPage}>
            <img src={ChevronIcon} style={{ transform: 'rotate(180deg)' }} alt="pagination"/>
          </PaginationButton>
        ) : <></>
      }
      <span>{pageNumber+1} of {totalPages}</span>
      {
        (pageNumber < totalPages - 1) ? (
          <PaginationButton onClick={handleNextPage}>
            <img src={ChevronIcon} alt="pagination" />
          </PaginationButton>
        ) : <></>
      }
   
    </PaginationContainer>
  )
}

export default Pagination;