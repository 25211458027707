import { Dispatch } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { EBulkOperationType } from 'ts/enums/bulkOperation'; 

export const setType = (type: EBulkOperationType) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_WIZARD_SET_TYPE,
    data: { type },
  };
  dispatch(action);
};

export const createProcessingFile = (file: any) => (dispatch: Dispatch): void => {
  const formData = new FormData();
  formData.append('file',file);

  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_CREATE_PROCESSING_FILE,
    payload: {
      method: 'POST',
      endpoint: `processing-file`,
      body: formData
    },
    components: ['create_processing_file'],
    delayLoading: 300
  };
  dispatch(action);
};


export const matchColumn = (column: string, header: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_MATCH_COLUMN,
    data: { column, header },
  };
  dispatch(action);
};

export const clearColumnMatching = (column: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_CLEAR_COLUMN_MATCHING,
    data: { column },
  };
  dispatch(action);
};

export const flushWizardState = () => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_WIZARD_FLUSH_STATE,
    data: {},
  };
  dispatch(action);
};