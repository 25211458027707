import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { EBulkOperationType } from 'ts/enums/bulkOperation';
import { setType } from 'data/actions/bulkOperationsWizard';
import { typeSelector } from 'data/selectors/bulkOperationsWizard';
import {
    TypeIconContainer,
    TypeContainer,
    TypeTitle
} from './styles';

const TypeStep: FC = () => {
    const dispatch = useDispatch();
    const type = useSelector(typeSelector);

    return (
        <>
                    <TypeContainer onClick={() => dispatch(setType(EBulkOperationType.EMAIL_SEARCH))}>
                        <TypeIconContainer active={type === EBulkOperationType.EMAIL_SEARCH}>
                            <SearchOutlined />
                        </TypeIconContainer>
                        <TypeTitle active={type === EBulkOperationType.EMAIL_SEARCH}>Search</TypeTitle>
                    </TypeContainer>
    
                    <TypeContainer onClick={() => dispatch(setType(EBulkOperationType.EMAIL_VERIFICATION))}>
                        <TypeIconContainer active={type === EBulkOperationType.EMAIL_VERIFICATION}>
                            <CheckOutlined/>
                        </TypeIconContainer>
                        <TypeTitle active={type === EBulkOperationType.EMAIL_VERIFICATION}>Verification</TypeTitle>
                    </TypeContainer>
        </>
    )
}

export default TypeStep;