import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import { startCase } from 'lodash';
import { CloseCircleFilled, CaretDownOutlined, CheckCircleFilled } from '@ant-design/icons';
import { matchingColumns } from 'ts/constants/bulkOperation';
import { typeSelector, matchingSelector } from 'data/selectors/bulkOperationsWizard';
import { matchColumn, clearColumnMatching } from 'data/actions/bulkOperationsWizard';
import { EBulkOperationType } from 'ts/enums/bulkOperation';

import { 
    MatchIconContainer,
    PreviewItem
} from './styles';

const { Option } = Select;

const MatchingSelect: FC<{ header: string }> = ({ header }) => {
    const dispatch = useDispatch();
    const type = useSelector(typeSelector) as EBulkOperationType;
    const matching = useSelector(matchingSelector);
    const columns = matchingColumns[type];

    return (
            <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
                <Select 
                    onChange={(column: string) => {
                        if (column) {
                            dispatch(matchColumn(column, header))
                        } else {
                            const column = Object.keys(matching).find(key => matching[key] === header);
                            if (column) dispatch(clearColumnMatching(column))
                        }
                        
                    }}
                    style={{ width: 200 }} 
                    suffixIcon={<CaretDownOutlined/>} 
                    allowClear
                >
                    { columns.map(column => <Option value={column} disabled={matching?.[column]} key={`match_${column}_key`}>{startCase(column)}</Option>)}
                </Select>
            </div>
    )
}

const MatchingIcon: FC<{ header: string }> = ({ header }) => {
    const matching = useSelector(matchingSelector);
    const isMatched = Object.values(matching).includes(header);

    return (
        <MatchIconContainer active={isMatched}>
            {isMatched ? <CheckCircleFilled />:  <CloseCircleFilled />}
        </MatchIconContainer>
    )
}

const columns = [
    {
        Header: 'Matched',
        accessor: (originalRow: any) => <MatchingIcon  header={originalRow?.column} />,
    },
    {
        Header: 'Header',
        accessor: (originalRow: any) => originalRow?.column,
    },
    {
        Header: 'Preview',
        accessor: (originalRow: any) => {
            return originalRow?.preview?.slice(0, 3)?.map((item: any, index: number) => <PreviewItem key={`preview_${index}_${originalRow?.column}`}>{item}</PreviewItem>)
        },
    },
    {
        Header: 'Action',
        accessor: (originalRow: any) => <MatchingSelect header={originalRow?.column} key={`${originalRow.column}_select`} />,
    },
];

export default columns;