import styled from "styled-components";
import { ICompanySearchContainerProps } from 'ts/interfaces/components/company.search.container';

export const CompanyInputField = styled.input`
    line-height: 19px;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(230, 234, 238);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 14px 13px 13px 14px;
    transition: all 1s ease 0s;

    &:hover, &:active, &:focus  {
      border: 1px solid rgb(87, 102, 236);
      outline: rgb(87, 102, 236);
      transition: all 0.2s ease-in-out 0s;
    }

    &:disabled {
      background: rgb(245, 247, 248);
      transition: all 1s ease 0s;
    }
`

export const CompanySearchContainer = styled.div<ICompanySearchContainerProps>`
    position: relative;
    flex: 0.4;
`;

export const CompanySearchResult = styled.div`
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    border: 1px solid rgb(230,234,238);
    background: white;
    z-index: 1000;
    max-height: 260px;
    overflow-y: scroll;
`;

export const CompanySearchItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    &:hover, &:active, &:focus  {
        background: rgb(245, 247, 248);
        transition: all 0.2s ease 0s;
    }
`;

export const CompanyIcon = styled.img`
    width: 32px;
    margin-right: 10px;
`;

export const NameDomainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 26px;
    justify-content: space-between;
`;

export const CompanyName = styled.span`
    font-size: 13px;
    line-height: 1;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CompanyDomain = styled.div`
    font-size: 12px;
    color: #a7a7a7;
    line-height: 1;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;