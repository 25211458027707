import { ISearchResult } from 'ts/interfaces/search/search';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ISearchResult = {};

const searchReducer = (state = initialState, action: IReduxAction): ISearchResult => {
  switch (action.type) {
    case EReduxTypes.SEARCH_EMAIL: {
      const searchResult = action.payload as ISearchResult;
      return searchResult;
    }
    default:
      return state;
  }
};

export default searchReducer;