import React from 'react';
import loadingIcon from 'assets/loading.svg';
import { Container } from './styles';

const FullScreenLoader = () => (
  <Container>
    <img src={loadingIcon} width={50} alt={"loading"} />
  </Container>
);

export default FullScreenLoader;
