import {
  FC,
  // useState,
  // useRef
} from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineCloudUpload } from 'react-icons/ai';

import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import { EModalTypes } from 'ts/enums/modal.types';
import { toggleModal } from 'data/actions/modals';
import {
  Container,
  Title,
  ButtonContainer,
  UploadIcon,
  Description,
  // VideoContainer,
  // Preview,
  // PlayIcon
} from './styles';

const BulkOperationsEmptyState: FC = () => {
  const dispatch = useDispatch();
  // const videoElement = useRef<any>(null);
  // const [showPreview, setShowPreview] = useState(true);

  // const runVideo = () => {
  //   setShowPreview(false);
  //   if (videoElement?.current) {
  //     videoElement?.current.play();
  //   }
  // }

  return (
    <Container>
      <Title>Upload your first file</Title>
      <Description>Launch your first file and start email finding today!</Description>
      <ButtonContainer>
        <Button size={ButtonHeight.MEDIUM} onClick={()=> dispatch(toggleModal(EModalTypes.CREATE_BULK_OPERATIONS,
          true))}> <UploadIcon>
            <AiOutlineCloudUpload />
          </UploadIcon> Upload File</Button>
      </ButtonContainer>
      {/* <VideoContainer>
        <Preview style={{ display: showPreview ? 'flex' : 'none' }}>
          <PlayIcon onClick={runVideo} />
        </Preview>
        <video style={{ visibility: showPreview ? 'hidden' : 'visible' }} ref={videoElement} width="544px"
          height="312px" controls>
          <source src="https://mailspot.s3.eu-north-1.amazonaws.com/MailSpot-Bulk-operations-Charles.mp4" type="video/mp4" />
        </video>
      </VideoContainer> */}
    </Container>
  )
}

export default BulkOperationsEmptyState;