import styled from "styled-components";

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonContainer = styled.div`
    width: 150px;
`;

export const UploadIcon = styled.span`
    font-size: 20px;
    margin-right: 5px;
`;