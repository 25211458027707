import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { GlobalStyle } from 'components/common/styles';
import GlobalFonts from 'fonts/fonts';
import { isUserAuthenticated } from 'data/selectors/user';
import AuthorizedUser from 'routers/AuthorizedUser';
import UnauthorizedUser from 'routers/UnauthorizedUser';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import DrawerController from 'components/drawerController';

const RouterContainer: FC = () => {
  const isAuth = useSelector(isUserAuthenticated);
  return (
      <Router>
        { isAuth ? <AuthorizedUser /> : <UnauthorizedUser /> }
      </Router>
  )
} 

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <GlobalFonts />
      <RouterContainer />
      <ToastContainer />
      <DrawerController />
    </>
  );
}

export default App;
