import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { Switch } from 'antd';

import Button from 'components/common/button';
import { Divider } from 'components/common/styles';
import { generatePurchaseUrl } from 'data/actions/billing';
import { currentPlanSelector } from 'data/selectors/subscriptions';
import { IUiPlan } from 'ts/interfaces/plan/plan';
import { EBillingPeriod } from 'ts/enums/billing.period';
import Plans from 'ts/constants/billing.plans';
//** Styles */
import {
  PlanListContainer,
  PlanContainer,
  PlanLabel,
  PlanPricingWrapper,
  PlanPrice,
  FeaturesContainer,
  FeatureItem,
  Title,
  BillingCycleContainer,
  ActiveButtonStyles,
  ButtonStyles
} from './styles';

const PlanItem: FC<{plan: IUiPlan, period: EBillingPeriod, active?: boolean}> = ({ plan, period, active }) => {
  const dispatch = useDispatch();    
  const price = period === EBillingPeriod.MONTHLY ? plan.price : plan.price - (plan.price * 0.2);
  return (
        <PlanContainer active={active}>
            <PlanLabel>{plan.name}</PlanLabel>
            <PlanPricingWrapper><PlanPrice>${price.toFixed(2)}</PlanPrice>/ Month</PlanPricingWrapper>
            <FeaturesContainer>
                {
                    plan.features.map((item: string) => <FeatureItem key={`${item}_feature`}><BsFillCheckCircleFill style={{ marginRight: 10 }} />{item}</FeatureItem>)
                }
                {
                    plan?.disabledFeatures?.map((item: string) => <FeatureItem key={`${item}_feature`}><BsXCircleFill color="#bfbfbf" style={{ marginRight: 10 }} />{item}</FeatureItem>)
                }
            </FeaturesContainer>
            <Divider />
            <Button empty style={active ? ActiveButtonStyles : ButtonStyles} onClick={() => dispatch(generatePurchaseUrl(plan.id, period))}>{active ? 'ACTIVE' : 'Choose Plan'}</Button>
        </PlanContainer>
  )
}

const PricingPage: FC = () => {
  const [isYearly, setIsYearly] = useState(false);
  const currentPlan = useSelector(currentPlanSelector);

  return (
    <>
      <div>
        <Title>The Right Plan for Your Business</Title>
        <BillingCycleContainer>
          MONTHLY
           <Switch onChange={(checked: boolean)=> setIsYearly(checked)}/>
            20% off paid annually
        </BillingCycleContainer>
        <PlanListContainer>
          {Plans.map((plan: IUiPlan) => (
            <PlanItem
              active={(plan?.id===currentPlan?._id)}
              key={`${plan.name}_pricing_card`} plan={plan}
              period={isYearly ? EBillingPeriod.YEARLY : EBillingPeriod.MONTHLY }
            />
          ))}
         </PlanListContainer>
      </div>
    </>
  )
}

export default PricingPage;
