import styled from 'styled-components';

export const Description = styled.div`
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const EmailInput = styled.input`
    line-height: 19px;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(230, 234, 238);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 14px 13px 13px 14px;
    transition: all 1s ease 0s;
    flex: 0.6;

    &:hover, &:active, &:focus  {
      border: 1px solid rgb(87, 102, 236);
      outline: rgb(87, 102, 236);
      transition: all 0.2s ease-in-out 0s;
    }

    &:disabled {
      background: rgb(245, 247, 248);
      transition: all 1s ease 0s;
    }
`

export const Button = styled.button`
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    display: block;
    width: 100%;
    background: ${props => props.disabled ? 'rgb(245, 246, 255)' : 'rgb(87, 102, 236)'};
    color: ${props => props.disabled ? 'rgb(186, 193, 247)' : 'rgb(255, 255, 255)'};
    border: ${props => props.disabled ? '1px solid rgb(245, 246, 255);' : '1px solid rgb(87, 102, 236);'};
    outline: rgb(87, 102, 236);
    border-radius: 8px;
    font-size: 16px;
    line-height: 18px;
    min-height: 48px;
    padding: 14px;
    min-width: auto !important;
    cursor: pointer;
    flex-basis: 15%;
    margin-left: 20px;

    &:hover {
      background: rgb(112, 126, 249);
      outline: rgb(112, 126, 249);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(112, 126, 249);
    }

    &:active {
      background: rgb(72, 86, 216);
      border: 1px solid rgb(72, 86, 216);
      color: rgb(255, 255, 255);
      outline: rgb(72, 86, 216);
      transform: scale(0.9);
    }

    &:focus {
      transition: all 0.2s ease-in-out 0s;
    }

    ${(props) => props.disabled && 'pointer-events: none;'}
`;

export const VerificationForm = styled.div`
  display: flex;
`;

export const MailIconContainer = styled.div`
    display: flex;
    align-items: center;
    color: #a7a7a7;
    padding: 0px 18px;
    font-size: 17px;
    border: 1px solid rgb(230,234,238);
    border-left: none;
    border-right: none;
    cursor: pointer;
    margin-left: -2px;
    margin-right: -2px;
`;
