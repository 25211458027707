import {
  FC,
  // useState,
  // useRef,
} from 'react';

import { useNavigate } from 'react-router-dom';

import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import {
  Container,
  Title,
  ButtonContainer,
  Description,
  // VideoContainer,
  // Preview,
  // PlayIcon
} from './styles';

const LeadsEmptyState: FC = () => {
  const navigate = useNavigate()

  // TO-DO: add video for leads table
  // const videoElement = useRef<any>(null);
  // const [showPreview, setShowPreview] = useState(true);

  // const runVideo = () => {
  //   setShowPreview(false);
  //   if (videoElement?.current) {
  //     videoElement?.current.play();
  //   }
  // }

  return (
    <Container>
      <Title>Create your first contact</Title>
      <Description>Search your first contact and it will be automatically saved to your contact list!</Description>
      <ButtonContainer>
        <Button size={ButtonHeight.MEDIUM} onClick={()=> navigate('/')}> 
          Search Contact
        </Button>
      </ButtonContainer>
      {/* <VideoContainer>
        <Preview style={{ display: showPreview ? 'flex' : 'none' }}>
          <PlayIcon onClick={runVideo} />
        </Preview>
        <video style={{ visibility: showPreview ? 'hidden' : 'visible' }} ref={videoElement} width="544px"
          height="312px" controls>
          <source src="https://mailspot.s3.eu-north-1.amazonaws.com/MailSpot-Bulk-operations-Charles.mp4" type="video/mp4" />
        </video>
      </VideoContainer> */}
    </Container>
  )
}

export default LeadsEmptyState;