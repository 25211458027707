import moment from 'moment'; 
import { IBulkOperation } from 'ts/interfaces/bulkOperations/bulkOperation';
import { startCase } from 'lodash';
import BulkOperationProgressBar from 'components/bulkOperationProgressBar';
import ActionBar from 'components/bulkOperationActionBar';

const BulkOperationColumns = [
    {
        Header: 'Type',
        accessor: (originalRow: IBulkOperation) => startCase(originalRow.type),
    },
    {
        Header: 'Status',
        accessor: (originalRow: IBulkOperation) => startCase(originalRow.status),
    },
    {
        Header: 'Process',
        accessor: (originalRow: IBulkOperation) => <BulkOperationProgressBar processingStatus={originalRow.processingStatus}/>,
    },
    {
        Header: 'Emails',
        accessor: (originalRow: IBulkOperation) => '18 emails found',
    },
    {
        Header: 'Date',
        accessor: (originalRow: IBulkOperation) => moment(originalRow.createdAt).format('MMMM Do, YYYY')
    },
    {
        Header: 'Action',
        accessor: (originalRow: IBulkOperation) => <ActionBar bulkOperationId={originalRow._id} processingStatus={originalRow.processingStatus}/>
    }
];

export default BulkOperationColumns;