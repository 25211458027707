import { AnyAction } from 'redux';
import { EDrawerTypes } from 'ts/enums/drawer.types';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const toggleDrawer = (visible: boolean, type?: EDrawerTypes, data?: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.TOGGLE_DRAWER,
    data: {
      type,
      visible,
      data
    }
  };
  return action;
};
