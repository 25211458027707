import { FC, useState } from 'react';
import AuthLayout from 'layouts/auth/authLayout';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAccount, signOut } from 'data/actions/user';
import { useNavigate } from 'react-router-dom';
import { CreateAccountLabel, Title, PasswordResetDescription, ErrorMessage } from './styles';
import Button from 'components/common/button';
import ConfirmationCode from 'components/confirmationCode';
import { getLoadingSelector } from 'data/selectors/loading';

const ConfirmEmailPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const loading = useSelector(getLoadingSelector('confirm_account'));

  const handleConfirmAccount = (e: any) => {
    e.preventDefault();
    dispatch(confirmAccount(parseInt(code, 10), setError));
  };

  const handleSingOut = () => {
    dispatch(signOut());
    navigate('/sign-in', { preventScrollReset: true })
  }

  return (
    <AuthLayout>
      <Title>Verify your email address</Title>
      { error && <ErrorMessage>{error}</ErrorMessage> }
      <PasswordResetDescription>We've just sent a 6-digit code to your email</PasswordResetDescription>
      <ConfirmationCode onChange={setCode} />
      <Button style={{ marginTop: 25 }} onClick={handleConfirmAccount} loading={loading}>Confirm</Button>
      <CreateAccountLabel onClick={handleSingOut}>No, thanks</CreateAccountLabel>
    </AuthLayout>
  )
} 

export default ConfirmEmailPage;