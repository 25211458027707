import { FC } from 'react';

import { SectionTitle } from 'components/common/styles';
import LeadsTable from 'components/tables/leadsTable';

const LeadsPage: FC = () => {
  return (
    <>
      <SectionTitle>All leads</SectionTitle>
      <LeadsTable />
    </>
  )
}

export default LeadsPage;