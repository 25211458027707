import styled from "styled-components";
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

export const ErrorIcon = styled(ExclamationCircleOutlined)`
  color: red;
`;

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: green;
`

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 25%;
  will-change: transform;
  transition: transform 200ms ease;

  &:hover, &:focus {
    background: rgb(245,247,248);
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    transform: scale(0.8);
  }


  font-size: 30px;
`;
