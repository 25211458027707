import { IUsage } from 'ts/interfaces/usage/usage'
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IUsage | {} = {};

const usageReducer = (state = initialState, action: IReduxAction): IUsage | {} => {
  switch (action.type) {
    case EReduxTypes.GET_USAGE_ANALYTICS: {
      return {
        ...state,
        usageAnalytics: action?.payload || [],
      };
    }
    case EReduxTypes.GET_USAGE_HISTORY: {
      return {
        ...state,
        usageHistory: action?.payload?.data || [],
        meta: action?.payload?.meta,
      }
    }
    default:
      return state;
  }
};

export default usageReducer;