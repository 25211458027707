import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IPagination } from 'ts/interfaces/common/pagination';
import { ILead } from 'ts/interfaces/leads/lead';

export const searchLeads = (pagination: IPagination, search?: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.FIND_LEADS,
    payload: {
      method: 'GET',
      endpoint: `leads?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}${search?.length ? `&search=${search}` : ''}`,
    },
    components: ['find_leads'],
  };

  return action;
};

export const setSelectedLeads = (leads: string[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_SELECTED_LEADS,
    data: {
      leads,
    }
  }
  return action;
};

export const deleteLeads = (ids: string[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_LEADS,
    payload: {
      method: 'DELETE',
      endpoint: 'leads',
      body: ids
    },
    forwardedData: ids
  };

  return action;
};

export const updateLead = (leadId: string, leadData: ILead): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_LEAD,
    payload: {
      method: 'PATCH',
      endpoint: `leads/${leadId}`,
      body: leadData,
    },
    components: ['update_lead'],
    forwardedData: {
      leadId,
      leadData
    }
  };

  return action;
};

export const updateLeadRelation = (relationAction: 'add' | 'remove', leadId: string, domain?: string, name?: string, email?: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_LEAD_RELATION,
    payload: {
      method: 'PATCH',
      endpoint: `leads/relation/${relationAction}/${leadId}`,
      body: {
        domain,
        name,
        email
      }
    },
    forwardedData: {
      action: relationAction,
      leadId,
      domain,
      email
    },
  };

  return action;
}
