import { FC, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/commonTable';
import Pagination from 'components/pagination';
import { orderMetaSelector, orderDataSelector } from 'data/selectors/order';
import { getOrderList } from 'data/actions/order';
import { ControlsBarContainer } from './styles';
import OrderTableColumns from './columns';

const OrdersTable: FC = () => {
  const dispatch = useDispatch();

  const { totalItems = 0, totalPages = 0 } = useSelector(orderMetaSelector);
  const orders = useSelector(orderDataSelector);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(5);

  useEffect(() => {
      dispatch(getOrderList({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  const columns = useMemo(
    () => OrderTableColumns, []
  );

  if (!orders.length) return null;

  return (
    <>
      <ControlsBarContainer>
        <Pagination
          totalItems={totalItems}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePage={setPageNumber}
          totalPages={totalPages}
        />
      </ControlsBarContainer>
      <Table columns={columns} data={orders} containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }} />
    </>
  );
};

export default OrdersTable;
