import { FC, useEffect, useState, useRef } from 'react';
import { Container, DigitNumber } from './styles';

const ConfirmationCode: FC<any> = ({ onChange }) => {
    const codeLength = 6;
    const [code, setCode] = useState<(string|number)[]>([]);
    const inputsRef = useRef<any[]>([]);
    const [currentFocus, setCurrentFocus] = useState(0);


    useEffect(() => {
        const initValue: any = [];
        for (let i=0; i<codeLength; i++) initValue.push('');
        setCode(initValue);
    }, []);

    useEffect(() => {
        if (inputsRef.current[currentFocus]) {
            inputsRef.current[currentFocus].focus()
        }
    }, [currentFocus, inputsRef]);

 
    const addToRefs = (el: any) => {
        if (el && !inputsRef.current.includes(el)) {
            inputsRef.current.push(el);
        }
    }

    useEffect(() => onChange(code.join('')), [code, onChange]);

    return (
        <Container> 
            {
               [...Array.from(Array(codeLength).keys())].map((item, index: number) => 
               <DigitNumber 
                    ref={addToRefs}
                    autoComplete={'off'}
                    maxLength={1} 
                    value={code[index]}
                    onPaste={(e) => {
                        const data = e.clipboardData.getData('Text');
                        const elements = data.split('');                        
                        const updatedCode = [...code];
                        elements.forEach((element, i) => {
                            const position = index + i;
                            if (position < codeLength) {
                                updatedCode[position] = parseInt(element, 10);
                            }
                        });
                        setCode(updatedCode);
                    }}
                    onChange={(e) => {
                        const updatedCode = [...code];
                        const number = parseInt(e.target.value, 10);
                        updatedCode[index] = (!isNaN(number)) ? number : '';
                        setCode(updatedCode);

                        if (index < codeLength - 1) setCurrentFocus(index+1);
                    }}
                    key={`digit_${item}`} 
                />)
            }
        </Container>
    )
}

export default ConfirmationCode;