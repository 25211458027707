import { FC } from 'react';
import { Container, Form, LogoContainer } from './styles';
import Logo from 'assets/logo.svg';

const AuthLayout: FC<any> = ({ children, ...props }) => {
    return (
        <Container {...props}>
            <Form autoComplete={'off'}>
                <LogoContainer src={Logo} />
                {children}
            </Form>
        </Container>
    )
}

export default AuthLayout;