import { ILeadsState } from 'ts/interfaces/leads/leads.state';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ILead } from 'ts/interfaces/leads/lead';
import { EEmailStatus } from 'ts/enums/email.status';

const initialState: ILeadsState = {
  meta: {
    totalItems: 0,
    totalPages: 0,
    search: '',
    pageSize: 10,
    pageNumber: 0,
  },
  data: [],
  selectedLeads: [],
};

const leadsReducer = (state = initialState, action: IReduxAction): ILeadsState | {} => {
  switch (action.type) {
    case EReduxTypes.FIND_LEADS: {
      return {
        ...state,
        meta: action.payload?.meta,
        data: action.payload?.data,
      }
    }

    case EReduxTypes.SET_SELECTED_LEADS: {
      return {
        ...state,
        selectedLeads: action.data.leads
      }
    }
    case EReduxTypes.UPDATE_LEAD: {
      return {
        ...state,
        data: state.data?.map((lead: ILead) => {
          if (lead._id === action.forwardedData.leadId) return action?.payload;
          return lead;
        })
      }
    }
    case EReduxTypes.DELETE_LEADS: {
      return {
        ...state,
        data: state.data?.filter((lead: ILead) => !action.forwardedData.includes(lead._id) ),
        meta: {
          ...(state as ILeadsState).meta,
          totalItems: action.forwardedData.length === 0 ? 0 : ((state as ILeadsState).meta?.totalItems || 0) - (action.forwardedData.length as number)
        },
        selectedLeads: [],
      }
    }
    case EReduxTypes.UPDATE_LEAD_RELATION: {
      if (action.forwardedData.action === 'add') {
        const leadIndex = state.data?.findIndex((lead) => lead._id === action.forwardedData.leadId);

        if (leadIndex !== undefined) {
          const updatedLead = state.data?.[leadIndex]
          if (updatedLead) {
            updatedLead.relations = [
              ...(updatedLead?.relations || []),
              { 
                company: action.forwardedData?.domain || action.forwardedData?.email?.split('@').pop(),
                email: { 
                  value: action.forwardedData?.email || `${updatedLead.firstName?.toLowerCase()}${updatedLead.lastName?.toLowerCase()}@${action.forwardedData.domain}`,
                  status: EEmailStatus.PROCESSING
                }
              }
            ];
          }
          const newData = [...(state?.data || [])];
          newData.splice(leadIndex, 1, updatedLead as ILead);

          return {
            ...state,
            data: newData
          }
        }
      }
      
      return state;
    }
    default:
      return state;
  }
};

export default leadsReducer;