import styled from 'styled-components';
import { ITagProps } from 'ts/interfaces/components/tag.props';

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const CheckItem = styled.div`
    font-size: 16px;
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 30px;
`;

export const Tag = styled.span<ITagProps>`
    background: ${(props) => props?.type === 'error' ? `#f50` :  'rgb(238, 245, 254)'};
    color: ${(props) => props?.type === 'error' ? `white` :  'rgb(87, 102, 236)'};
    padding: 4px 12px;
    border-radius: 50px;
    display: flex;
    line-height: 17px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 305px;
`;