import styled from "styled-components";
import { FlexRowContainer } from 'components/common/styles';

export const ControlsBarContainer = styled.div`
    background: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
`;

export const Cell = styled(FlexRowContainer)`
  justify-content: flex-start;
`;
