import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'TTNorms';
}

.ant-result {
  padding: 0;
}

.ant-result-icon {
  margin-bottom: 10px;
}

.ant-result-subtitle {
  font-size: 16px;
  color: #40413f;
}

a {
  color: #5766ec;

  &:hover {
    color: #5766ec;
    opacity: 0.8;
  }
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-upload {
  padding: 15px 50px;
  will-change: transform;
  transition: transform 200ms ease;

  &:active {
    transform: scale(0.99);
  }
}

.ant-select-selector {
  border-radius: 8px !important;
}

.ant-tabs-tab {
  padding: 10px 3px;
}

.ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 3px solid #5766ec !important;
    z-index: 2;
}

.ant-tooltip-inner {
  text-align: center;
}

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

&::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

&::-webkit-scrollbar-corner {
  width: 0px;
  height: 0px;
}

&::-webkit-scrollbar-thumb:hover {
  background-color: #76767e;
}

&::-webkit-scrollbar-button {
  display:none;
}
`;

export const SectionTitle = styled.div`
  color: #40413f;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export const FlexColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FlexRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Dot = styled.div<{ error?: boolean  }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${(props) => props.error ? 'rgb(228, 77, 95)' : '#52c41a'};
`;

export const Input = styled.input`
    line-height: 19px;
    width: 100%;
    font-size: 14px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 9px 13px 8px 14px;

    &:focus  {
      outline-color: #2684FF;
    }

    &:disabled {
      background-color: hsl(0, 0%, 95%);
      border-color: hsl(0, 0%, 90%);
    }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6eaee;
  margin: 20px 0;
`;

export const Description = styled.div`
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const TextArea = styled.textarea`
  resize: none;
  line-height: 19px;
  width: 100%;
  font-size: 16px;
  border: 1px solid rgb(230, 234, 238);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 500;
  padding: 14px 13px 13px 14px;
  transition: all 1s ease 0s;
  flex: 1;

  &:hover, &:active, &:focus  {
    border: 1px solid rgb(87, 102, 236);
    outline: rgb(87, 102, 236);
    transition: all 0.2s ease-in-out 0s;
  }
`

export const SettingPageHeader = styled.span`
    color: #40413f;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`;

export const SectionHeader = styled(SettingPageHeader)`
  font-size: 16px;
  font-weight: 550;
`;

export const SettingPageDescription = styled.span`
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const SectionDescription = styled(SettingPageDescription)`
  font-size: 13px;
`;

export const SectionItem = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: 100%;

  > div {
    width: ${(props) => props?.fullWidth ? 100 : 50}%;
    .ant-radio-inner:after {
      background-color: #5766ec;
    }

    .ant-radio-checked:after {
      border: 1px solid #5766ec;
    }

    .ant-radio-checked .ant-radio-inner  {
      border-color: #5766ec;
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      border-color: #5766ec;
      box-shadow: 0 0 0 2px rgb(87 102 236 / 20%)
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #5766ec;
    }
  }
`;
