import { FC, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { autocompleteCompany } from 'data/actions/companies';
import { suggestedCompaniesSelector } from 'data/selectors/companies';
import { EmailSearchInputProps } from 'ts/types/email.search.input.props';
import { searchEmail } from 'data/actions/search';

import { 
  CompanyInputField,
  CompanySearchContainer,
  CompanySearchResult,
  CompanySearchItem,
  CompanyIcon,
  NameDomainContainer,
  CompanyName,
  CompanyDomain
} from './styles';

const CompanyInput: FC<EmailSearchInputProps> = (props: EmailSearchInputProps) => {
    const [showResult, setShowResult] = useState(true);
    const suggestedCompanies = useSelector(suggestedCompaniesSelector);
    const [companyName, setCompanyName] = useState<any>('');
    
    const ref = useRef();
    const dispatch = useDispatch();

    useOnClickOutside(ref, () => setShowResult(false));

    useEffect(() => {
      if (suggestedCompanies.length) setShowResult(true);
    }, [suggestedCompanies])

    const handleChangeInput = (company: string) => {
        setCompanyName(company);
        if (company) dispatch(autocompleteCompany(company, props.excludedDomains));
    }

    const setCompany = (company: any, search?: boolean) => {
      setCompanyName(company);
      if (search && props?.name) {
        dispatch(searchEmail(props.name, company?.domain));
      } else {
        props.setCompany(company);
      }
      setShowResult(false);
  }

    
    return (
        <CompanySearchContainer style={props.style} ref={ref}>
        <CompanyInputField
            style={props.style}
            value={companyName?.name} 
            placeholder="Company..." 
            onChange={(e) => handleChangeInput(e?.target?.value)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                setCompany(companyName, true);
              }
            }}
            onBlur={(e) => setCompany(companyName)}
        />
        { (companyName && showResult && suggestedCompanies?.length) ? (
             <CompanySearchResult style={props.resultStyle} >
                 {  suggestedCompanies.map((suggestedCompany: any, index: number) => (
                        <CompanySearchItem key={index} onMouseDown={(e) => {
                          e.preventDefault();
                          setCompany(suggestedCompany, true);
                        }}>
                            <CompanyIcon src={suggestedCompany?.logo} />
                            <NameDomainContainer>
                                <CompanyName>{suggestedCompany?.name}</CompanyName>
                                <CompanyDomain>{suggestedCompany?.domain}</CompanyDomain>
                            </NameDomainContainer>
                        </CompanySearchItem>
                    )) 
                }
             </CompanySearchResult>
        ) : <></>
        }
        </CompanySearchContainer>
    )
}

export default CompanyInput;