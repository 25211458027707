import styled from 'styled-components';

const ACTIVE_COLOR = "#1890ff";
const DISABLED_COLOR = "rgba(0, 0, 0, 0.25)";

export const PreviewItem = styled.p`
  margin: 0;
  font-size: 11px;
  opacity: .7;
  text-align: center;
`;

export const MatchIconContainer = styled.div<{active?: boolean}>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: ${(props => props.active ? ACTIVE_COLOR : DISABLED_COLOR)};
`;