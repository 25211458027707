import moment from 'moment'; 
import { BiEdit, BiTrash } from 'react-icons/bi';

import { ILead } from 'ts/interfaces/leads/lead';
import { MoreButtonContainer } from './styles';
import EmailCell from '../../emailCell'

import AvatarCheckbox from 'components/avatarCheckbox';

const LeadsTableColumns = (handleEdit: unknown, handleDelete: unknown) => {
  return [
    {
        Header: 'Name',
        accessor: (originalRow: ILead) => {
            const name = `${originalRow.firstName} ${originalRow.lastName}`;
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <AvatarCheckbox originalRow={originalRow} />
                  {name}
                </div>
            )
        },
    },
    {
        Header: 'Email',
        accessor: (originalRow: ILead) => { // todo: remade for all relations
            const email = originalRow?.relations?.[0]?.email?.value;
            const status = originalRow?.relations?.[0]?.email?.status;

            return <EmailCell email={email} status={status} />
        },
    },
    {
        Header: 'Date',
        accessor: (originalRow: ILead) => moment(originalRow.createdAt).format('MMMM Do, YYYY')
    },
    {
        Header: 'Action',
        accessor: (originalRow: ILead) => (
          <MoreButtonContainer>
            <BiEdit
              size={30} 
              onClick={() => {
                if (typeof handleEdit === 'function') handleEdit(originalRow)
              }}
            />
            <BiTrash
              size={30}
              fill={"red"}
              onClick={() => {
                if (typeof handleDelete === 'function') handleDelete([originalRow?._id])
              }}
            />
          </MoreButtonContainer>
        )
    },
  ];
}

export default LeadsTableColumns;