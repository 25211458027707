import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsageAnalytics } from 'data/actions/usage';
import { Select } from 'antd';
import { Column } from '@ant-design/charts';
import { usageAnalyticsSelector } from 'data/selectors/usage';
import { userTimezone } from 'data/selectors/user';
import { options, generateAnalytics } from './configuration';
import { Header, ChartContainer } from './styles';

const UsageChart: FC<{ period: string, setPeriod: any, type: string, setType: any }> = ({ period, setPeriod, type, setType }) => {
  const dispatch = useDispatch();
  const usageAnalytics = useSelector(usageAnalyticsSelector);
  const { Option } = Select;
  const timezone = useSelector(userTimezone);
  useEffect(() => {
    dispatch(getUsageAnalytics(period, type));
  }, [period, type, dispatch]);
  const data = generateAnalytics(usageAnalytics, period, timezone || 'GMT');

  return (
    <>
      <ChartContainer>
      <Header>
        <Select value={type} onChange={(value) => setType(value)} style={{ width: 220, marginRight: 15, display: 'inline-flex' }}>
          <Option value="">All</Option>
          <Option value="email_verification">Email verification</Option>
          <Option value="email_search">Email search</Option>
        </Select>
        <Select value={period} onChange={(value) => setPeriod(value)} style={{ width: 120, display: 'inline-flex' }}>
          <Option value="month">Last month</Option>
          <Option value="day">Last day</Option>
          <Option value="hour">Last hour</Option>
        </Select>
      </Header>
      <Column {...options} data={data} />
    </ChartContainer>
    </>
  );
};

export default UsageChart;
