import { FC, useState } from 'react';
import AuthLayout from 'layouts/auth/authLayout';
import EmailInput from 'components/common/emailInput';
import PasswordInput from 'components/common/passwordInput';
import { ErrorMessage, Label, CreateAccountLabel, Title } from './styles';
import Button from 'components/common/button';
import { signUp } from 'data/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLoadingSelector } from 'data/selectors/loading';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { FcGoogle } from 'react-icons/fc';

const SignUpPage: FC = () => {
    const dispatch = useDispatch();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const loading = useSelector(getLoadingSelector('sign_up'));
    const googleLoading = useSelector(getLoadingSelector('google_auth'));
    const google_loading = useSelector<boolean>(getLoadingSelector('google_auth'));

    const handleSignUp = (e: any) => {
        e.preventDefault();
        dispatch(signUp(email, password, setError));
    };

    const connectGmailAccount = (e: any) => {
      e.preventDefault();
      const url = buildOauthRequestUrl();
      window.location.replace(url);
    };
    
    return (
        <AuthLayout>
            <Title>Create account</Title>
            { error && <ErrorMessage>{error}</ErrorMessage> }
            <EmailInput value={email} onChange={(e: any) => setEmail(e?.target?.value)} />
            <PasswordInput value={password} onChange={(e: any) => setPassword(e?.target?.value)}/>
            <Button style={{marginTop: 25}} onClick={handleSignUp} loading={loading || googleLoading}>Continue</Button>
            <Label>or</Label>
            <Button onClick={connectGmailAccount} loading={google_loading} empty><FcGoogle size={18} style={{ marginRight: 5 }}/>Sign up with Google</Button>
            <CreateAccountLabel><Link to="/sign-in"> I already have an account. Sign In</Link></CreateAccountLabel>
        </AuthLayout>
    )
}

export default SignUpPage;
