import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { searchEmail } from 'data/actions/search';
import { getLoadingSelector } from 'data/selectors/loading';
import CompanyInputField from 'components/companyInput';
import ContentBox from 'components/contentBox';

import { 
  Description,
  EmailInput,
  Button,
  VerificationForm,
  MailIconContainer,
} from './styles';

export const EmailSearchInput: FC = () => {
  const loading = useSelector(getLoadingSelector('search_email'));
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [company, setCompany] = useState<any>();

  return (
    <>
      <ContentBox>
        <Description>Enter a full name and the domain name of the email address</Description>
        <VerificationForm>
          <EmailInput
            placeholder="Enter full name..."
            disabled={loading}
            value={name}
            onChange={(e)=> setName(e?.target?.value)}
          />
          <MailIconContainer>@</MailIconContainer>
          <CompanyInputField setCompany={setCompany} />
          <Button
            onClick={()=> dispatch(searchEmail(name, company?.domain || ''))}
            disabled={loading || !name || !company}>
            <SearchOutlined />
          </Button>
        </VerificationForm>
      </ContentBox>
  </>
  )
}

export default EmailSearchInput;