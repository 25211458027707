import styled from "styled-components";
const TEXT_COLOR = "#1f1f1f";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    margin-bottom: 30px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
`;

export const ControlButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  will-change: transform;
  transition: transform 200ms ease;

  &:hover, &:focus {
    background: rgb(245,247,248);
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    transform: scale(0.8);
  }


  font-size: 30px;
  color: #5766ec;
`;

export const Title = styled.div`
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: ${TEXT_COLOR};
`;
