import styled from 'styled-components';

export const PaginationButton = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  will-change: transform;
  transition: transform 200ms ease;
  

  &:hover, &:focus {
    background: rgb(245,247,248);
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    transform: scale(0.8);
  }


  img {
    width: 20px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 10px;
    line-height: 0;
    font-size: 16px;
  }
`;