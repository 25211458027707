import { FC, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/commonTable';
import { getBulkOperationsList } from 'data/actions/bulkOperations';
import { bulkOperationsDataSelector, bulkOperationsMetaSelector } from 'data/selectors/bulkOperations';
import { SearchOutlined } from '@ant-design/icons';
import Pagination from 'components/pagination';
import { IBulkOperation } from 'ts/interfaces/bulkOperations/bulkOperation';
import BulkOperationColumns from './columns';
import { 
    ControlsBarContainer, 
    SearchInput, 
} from './styles';

const BulkOperationsTable: FC = () => {
    const dispatch = useDispatch();
    const bulkOperations: IBulkOperation[] = useSelector(bulkOperationsDataSelector);
    const { totalItems = 0, totalPages = 0 } = useSelector(bulkOperationsMetaSelector);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize] = useState(10);

    useEffect(() => {
      dispatch(getBulkOperationsList({ pageNumber, pageSize }))
    }, [dispatch, pageNumber, pageSize]);

    const columns = useMemo(
        () => BulkOperationColumns, []
    )

    return (
        <>
            <ControlsBarContainer>
                <SearchInput>
                <SearchOutlined />
                    <input placeholder="Search bulk operation by name..." />
                </SearchInput>
                <Pagination
                    totalItems={totalItems}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    onChangePage={setPageNumber}
                    totalPages={totalPages}
                />
            </ControlsBarContainer>
            <Table columns={columns} data={bulkOperations} />
        </>
    )
}

export default BulkOperationsTable;