import { createSelector } from 'reselect';

const baseUserSelector = (state: any) => state?.user;

export const isUserAuthenticated = createSelector(
    baseUserSelector,
    (user) => user?.tokenPayload && user?.tokenPayload?.emailVerified,
);

export const shouldConfirmEmail = createSelector(
    baseUserSelector,
    (user) => user?.tokenPayload?.email && !user?.tokenPayload?.emailVerified,
);

export const userEmail = createSelector(
  baseUserSelector,
  (user) => user?.tokenPayload?.email,
);

export const userTimezone = createSelector(
  baseUserSelector,
  (user) => user?.userData?.timezone,
);

export const userFirstName = createSelector(
  baseUserSelector,
  (user) => user?.userData?.firstName,
)

export const userLastName = createSelector(
  baseUserSelector,
  (user) => user?.userData?.lastName,
)

export const isGoogleUser = createSelector(
  baseUserSelector,
  (user) => user?.userData?.googleAuth,
)


const baseUserSettingsSelector = (state: any) => state?.user?.userSettings;

export const userSettings = (property?: string) => createSelector(
  baseUserSettingsSelector,
  (userSettings) => property ? userSettings?.[property] : userSettings,
)

export const userBillingEmail = createSelector(
  baseUserSelector,
  (user) => user?.userData?.billingEmail || '',
);

