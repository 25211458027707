import styled from 'styled-components';

export const EmailContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 10px;
        line-height: 1px;
    }
`;

export const ControlsBarContainer = styled.div`
    background: white;
    padding: 20px 30px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;
export const SearchInput = styled.div`
    display: flex;
    align-items: center;
    font-size: 17px;

    input {
      border: none;
      margin-left: 10px;
      width: 300px;

      &:focus {
        outline: none;
      }
    }
`;