import { combineReducers, Reducer } from 'redux';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import loading from './loading';
import verification from './verification';
import companies from './companies';
import search from './search';
import user from './user';
import credits from './credits';
import leads from './leads';
import bulkOperations from './bulkOperations';
import bulkOperationsWizard from './bulkOperationWizard';
import usage from './usage';
import modals from './modals';
import subscriptions from './subscriptions';
import application from './application';
import drawer from './drawer';
import order from './order'

const combinedReducer = combineReducers({
  verification,
  companies,
  loading,
  search,
  user,
  credits,
  leads,
  bulkOperations,
  bulkOperationsWizard,
  usage,
  modals,
  subscriptions,
  application,
  drawer,
  order
});

const rootReducer: Reducer = (state: any, action: IReduxAction) => {
  return combinedReducer(state, action);
};

export default rootReducer;