import { FC } from 'react';
import { StepsContainer, StepItem } from './styles';

const Steps: FC<{ steps: string[], currentStep: number }> = ({ steps, currentStep }) => {
    return (
        <StepsContainer>
            { steps.map((step, index) =>  <StepItem key={`step_${step}_${index}`} done={index < currentStep} active={index === currentStep}> <span>{step}</span></StepItem>) }
        </StepsContainer>    
    )
}

export default Steps;