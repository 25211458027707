import { Middleware } from 'redux';
import { triggerLoadingAction } from 'data/actions/loading';
import { signOut } from 'data/actions/user';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

import axios, { AxiosResponse } from 'axios';
import { ILoadingState } from 'ts/interfaces/loading/loading';

const apiMiddleware = (): Middleware => () => (next: any) => async (action: IReduxAction) => {
  if (!action) return false;
  
  const host = action?.payload?.host;
  const event = action?.event;
  const endpoint = action?.payload?.endpoint;
  const method = action?.payload?.method;
  const body = action?.payload?.body;
  const params = action?.payload?.params;
  const data = action?.data;
  const components = action?.components as Array<keyof ILoadingState>;
  const delayLoading = action?.delayLoading;
  const skipAuth = action?.skipAuth;
  const forwardedData = action?.forwardedData;
  const onSuccess = action?.onSuccess;
  const onFailed = action?.onFailed;

  const apiHost = (host) ? host : process.env.REACT_APP_API_URL;

  if (data || event) return next(action);
  

  let loading = true;
  setTimeout(() => {
    if (loading && components) {
      triggerLoadingAction(components, true, next);
    }
  }, delayLoading);

  const headers: any = {};

  if (!skipAuth) {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  try {
    const res: AxiosResponse = await axios({
      url: `${apiHost}/${endpoint}`,
      method,
      data: body,
      params,
      headers
    });

    const data = res?.data;
    if (onSuccess) onSuccess(data);

    loading = false;
    if (components) setTimeout(() => triggerLoadingAction(components, false, next), 0);
    
    return next({
      ...action,
      payload: data,
      forwardedData
    });
  } catch (err: any) {
    loading = false;
    if (components) setTimeout(() => triggerLoadingAction(components, false, next), 0);

    if (err?.response?.status === 401 && apiHost === process.env.REACT_APP_API_URL) {
      signOut()(next);
    }
    if (onFailed) return onFailed(err);
    return false;
  }
};

export default apiMiddleware;