import React, { useState, FC } from 'react';
import { Tooltip } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { EEmailStatus } from 'ts/enums/email.status';
import { EmailCellProps } from 'ts/types/email.cell.props'
import { EmailContainer } from './styles';

const EmailCell: FC<EmailCellProps> = ({ email, status, company }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = (e: any) => {
    if (email) {
      e.stopPropagation();
      e.preventDefault();
      window.navigator.clipboard.writeText(email);
      setIsCopied(true)
    }
  }

  const handleMouseLeave = (e: any) => {
    e.stopPropagation();
    setIsCopied(false)
  }

  return (
    <EmailContainer>
      { (status === EEmailStatus.VALID) ? <CheckCircleFilled style={{ color: '#52c41a' }}/> : <WarningFilled style={{ color: '#faad14' }}/> }
      <Tooltip title={isCopied ? 'Copied!' : `${company ? `"${company}" - ` : ''}Copy to clipboard`}>
        <span onClick={handleCopy} onMouseLeave={handleMouseLeave}>
          {email}
        </span>
      </Tooltip>
    </EmailContainer>
  )

}

export default EmailCell;