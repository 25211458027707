import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from 'antd';
import { createProcessingFile } from 'data/actions/bulkOperationsWizard';
import { flushWizardState } from 'data/actions/bulkOperationsWizard'; 
import { getLoadingSelector } from 'data/selectors/loading';
import { processingFileSelector } from 'data/selectors/bulkOperationsWizard';
import bytesToSize from 'helpers/bytesToSize';
import { 
    DraggerIcon,
    Container,
    FileResultContainer,
    FileIcon,
    FileData,
    FileName,
    FileSize,
    ClearFileIcon,
    CenteredFlex
} from './styles';
import csvIcon from 'assets/csv.png';
import loadingIcon from 'assets/loading.svg';
import folderIcon from 'assets/folder.png';
import { BsX } from 'react-icons/bs';
const { Dragger } = Upload;

const FileStep: FC = () => {
    const dispatch = useDispatch();
    const processingFile = useSelector(processingFileSelector);
    const loading = useSelector(getLoadingSelector('create_processing_file'));

    return (
        <Container>
        <Dragger
            name="file"
            accept="text/csv"
            multiple={false}
            showUploadList={false}
            disabled={false}
            customRequest={(file: any) => {
                dispatch(createProcessingFile(file?.file))
            }}
        >
            <DraggerIcon src={(loading) ? loadingIcon : folderIcon} />
            <p className="ant-upload-text">Select a CSV file to import</p>
            <p className="ant-upload-hint">Or drag and drop it here</p>
            <p className="ant-upload-hint">Your file must use commas or semicolons as column delimiters.</p>
        </Dragger>
        {
            processingFile && (
                <FileResultContainer>
                    <CenteredFlex>
                        <FileIcon src={csvIcon} />
                        <FileData>
                            <FileName>{processingFile?.sourceFile?.split('/')?.pop()}</FileName>
                            <FileSize>{bytesToSize(processingFile?.size)}</FileSize>
                        </FileData>
                    </CenteredFlex>
                    <ClearFileIcon onClick={() => dispatch(flushWizardState())}><BsX /></ClearFileIcon>
                </FileResultContainer>
            )
        }
        </Container>
    )
}

export default FileStep;