import { Dispatch } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IPagination } from 'ts/interfaces/common/pagination';
import { IBulkOperation } from 'ts/interfaces/bulkOperations/bulkOperation';
import { EBulkOperationType } from 'ts/enums/bulkOperation'; 

export const getBulkOperationsList = (pagination: IPagination) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_BULK_OPERATIONS_LIST,
    payload: {
      method: 'GET',
      endpoint: `bulk-operation?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&sort=createdAt&order=desc`,
    },
    components: ['bulk_operations_list'],
    onSuccess: (data) => {
      data?.data?.forEach((bulkOperation: IBulkOperation) => dispatch({
        type: EReduxTypes.BULK_OPERATION_STATUS,
        payload: {
          method: 'GET',
          endpoint: `bulk-operation/${bulkOperation._id}/status`,
        },
        components: ['BULK_OPERATION_STATUS'],
      }));
    } 
  };
  dispatch(action);
};

export const getBulkOperationStatus = (bulkOperationId: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.BULK_OPERATION_STATUS,
    payload: {
      method: 'GET',
      endpoint: `bulk-operation/${bulkOperationId}/status`,
    },
  };
  dispatch(action);
};


export const createBulkOperation = (type: EBulkOperationType, processingFile: string, matching: any) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_BULK_OPERATION,
    payload: {
      method: 'POST',
      endpoint: `bulk-operation`,
      body: {
        type,
        processingFile,
        matching
      }
    },
    onSuccess: (data) => { 
      dispatch({
        type: EReduxTypes.START_BULK_OPERATION,
        payload: {
          method: 'POST',
          endpoint: `bulk-operation/${data._id}/start`,
        },
      })
    }
  };
  dispatch(action);
};

export const getBulkOperationResult = (bulkOperationId: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_BULK_OPERATION_RESULT,
    payload: {
      method: 'GET',
      endpoint: `bulk-operation/${bulkOperationId}/result`,
    },
    onSuccess: (data) => {
      if (data?.resultFile) window.location.href = data?.resultFile;
    }
  };
  dispatch(action);
};

export const deleteBulkOperation = (bulkOperationId: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_BULK_OPERATION,
    payload: {
      method: 'DELETE',
      endpoint: `bulk-operation/${bulkOperationId}`,
    },
  };
  dispatch(action);
};

export const startBulkOperation = (bulkOperationId: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.START_BULK_OPERATION,
    payload: {
      method: 'POST',
      endpoint: `bulk-operation/${bulkOperationId}/start`,
    },
  };
  dispatch(action);
};