import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #f6f6f6;
    align-items: center;
`;


export const ContentContainer = styled.main`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 55px;
    flex-direction: column;
    height: calc(100vh - 65px);
    overflow-y: scroll;
    margin-top: 20px;

    @media (max-width: 1249px) {
        padding: 0 20px;
    }
`;