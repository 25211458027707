import styled from "styled-components";

export const StepContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    width: 100%;
    height: 280px;
`;

export const ButtonContainer = styled.div`
    width: 200px;
`;
