export enum EBulkOperationStatus {
    NEW = 'new',
    PROCESSING = 'processing',
    DONE = 'done',
    FAILED = 'failed',
  }
  
  export enum EBulkOperationType {
    EMAIL_SEARCH = 'email_search',
    EMAIL_VERIFICATION = 'email_verification',
  }
  