import { Middleware } from 'redux';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IMessageEvent } from 'ts/interfaces/common/events';

const eventsMiddleware = (): Middleware => () => (next: any) => async (action: IReduxAction) => {
    const event = action?.event;
    if (!event) return next(action);
    
    const apiHost = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    const eventSource = new EventSource(`${apiHost}/${event.path}?jwtToken=${accessToken}`);

    eventSource.onopen = (e) => {
        console.log("Event source was opened");
    };

    eventSource.onerror = (err) => {
        console.log("Event source error", err);
    };

    eventSource.onmessage = ({ data }) => {
        try {
          const message = JSON.parse(data) as IMessageEvent;
          if (message.type) next({
              type: message.type,
              payload: message.data
          })
        } catch (err) { console.log('[EVENT SOURCE] message error', err); } // eslint-disable-line no-console
    };

}

export default eventsMiddleware;