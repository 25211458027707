import styled from 'styled-components';
import { MenuItem } from '../styles';

export const SettingPageHeader = styled.span`
    color: #40413f;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`;

export const SettingPageDescription = styled.span`
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;

    @media (max-width: 1249px) {
        flex-direction: column;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const AccountSettingsContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1249px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 5px;

    @media (max-width: 1249px) {
        width: 100%;
        padding: 0px;
    }
`;

export const InputLabel = styled.span`
    margin-bottom: 2px;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;

    @media (max-width: 1249px) {
        width: 100%;
        padding: 0px;
    }
`;

export const BaseInput = styled.input`
    font-size: 14px;
    border: 1px solid rgb(230,234,238);
    border-radius: 4px;
    background: rgb(255,255,255);
    color: rgb(0,0,0);
    font-weight: 500;
    padding: 10px 15px;
`;

export const DeleteButton = styled(MenuItem)`
  color: #5766ec;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: auto;

  @media (max-width: 1249px) {
    padding: 10px 0;
  }
`