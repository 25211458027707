import { createSelector } from 'reselect';
import { EFeatures } from 'ts/enums/features';

const baseSubscriptionsSelector = (state: any) => state?.subscriptions;

export const totalCreditsSelector = createSelector(
    baseSubscriptionsSelector,
    (subscriptions) => subscriptions?.plan?.credits || 0,
);

export const currentSubscriptionSelector = createSelector(
    baseSubscriptionsSelector,
    (subscriptions) => subscriptions,
);

export const currentPlanSelector = createSelector(
    baseSubscriptionsSelector,
    (subscriptions) => subscriptions?.plan
); 

export const isFeatureAvailableSelector = (feature: EFeatures) => createSelector(
    baseSubscriptionsSelector,
    (subscriptions) => subscriptions?.plan?.features?.includes(feature)
);