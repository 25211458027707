import { Dispatch } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const searchEmail = (name: string, company: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.SEARCH_EMAIL,
    payload: {
      method: 'GET',
      endpoint: `search/contact?company=${company}&name=${name}`,
    },
    components: ['search_email'],
  };
  dispatch(action);
};
