import { createSelector } from 'reselect';

const baseCompanySelector = (state: any) => state?.companies;

export const suggestedCompaniesSelector = createSelector(
    baseCompanySelector,
    (company) => company?.suggestedCompanies || [],
);

export const leadCompaniesSelector = createSelector(
  baseCompanySelector,
  (company) => company?.loadedCompanies || [],
);
