import { Result } from 'antd';
import { useSelector } from 'react-redux';
import { getLoadingSelector } from 'data/selectors/loading';
import { verificationSelector } from 'data/selectors/verification';
import ContentBox from 'components/contentBox';
import loadingIcon from 'assets/loading.svg';
import { IVerificationResult } from 'ts/interfaces/verification/verification.result';
import { 
    CheckContainer,
    CheckItem,
    Tag,
    LoadingContainer
} from './styles';

const EmailVerificationResult = () => {
  const loading = useSelector(getLoadingSelector('verification'));
  const verification: IVerificationResult = useSelector(verificationSelector);


  const getEmailStatus = () => {
    if (verification.deliverable && !verification.catchAll) return "success";
    if (verification.deliverable && verification.catchAll) return "warning";
    if (!verification.deliverable && !verification.catchAll) return "error";
  }

  const getEmailLabel = () => {
    if (verification.deliverable && !verification.catchAll) return "Valid";
    if (verification.deliverable && verification.catchAll) return "Accept all";
    if (!verification.deliverable && !verification.catchAll) return "Invalid";
  }

  const getEmailStatusDescription = () => {
    if (verification.deliverable && !verification.catchAll) return "This email address can be used safely.";
    if (verification.deliverable && verification.catchAll) return "The domain name accepts all the email addresses.";
    if (!verification.deliverable && !verification.catchAll) return "This email address isn't used to receive emails.";
  }

    if (!verification?.address && !loading) return <></>;
    return (
        <ContentBox>
          {
            (loading) ? (
              <LoadingContainer>
                <img src={loadingIcon} width={50} alt={"loading"} />
              </LoadingContainer>
            ) : (
          <>
          <Result
                    status={getEmailStatus()}
                    title={getEmailLabel()}
                    subTitle={getEmailStatusDescription()} />
            <CheckContainer>
                      <CheckItem> <span> Format </span> <Tag type={!verification.validFormat ? 'error' : 'success'}>{ verification.validFormat ? 'Valid' : 'Invalid' }</Tag></CheckItem>
                      <CheckItem> <span> Provider </span> <Tag type={!verification.provider ? 'error' : 'success'}>{ verification.provider || 'Not available' }</Tag></CheckItem>
                      <CheckItem> <span> Deliverable </span> <Tag type={!verification.deliverable ? 'error' : 'success'}>{ verification.deliverable ? 'Yes' : 'No' }</Tag></CheckItem>
                      <CheckItem> <span> Server status </span> <Tag type={!verification.hostExists ? 'error' : 'success'}>{ verification.hostExists ? 'Active' : 'Inactive' }</Tag></CheckItem>
                      <CheckItem> <span> Disposable </span> <Tag type={verification.disposable ? 'error' : 'success'}>{ verification.disposable ? 'Yes' : 'No' }</Tag></CheckItem>
                      <CheckItem> <span> Type </span> <Tag type='info'>Personal</Tag></CheckItem>
            </CheckContainer></>
            )
          }
        </ContentBox>
    )
}

export default EmailVerificationResult;