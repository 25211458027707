import React, { FC } from 'react';
import { CustomSelect, CustomDropdownContainer, CaretDown, SearchIcon } from './styles';
import { SelectProps } from 'antd';

const CommonSelect: FC<SelectProps<any>> = ({ children, ...props }) => {
  return (
    <CustomSelect
      suffixIcon={({ open, searchValue }: any) => (searchValue !== '' ? <SearchIcon/> :<CaretDown focused={open ? 1 : 0} />)}
      dropdownRender={(menu: any) => <CustomDropdownContainer>{menu}</CustomDropdownContainer>}
      {...props}
    >
      {children}
    </CustomSelect>
  )
    
}

export default CommonSelect;
