import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { searchSelector } from 'data/selectors/search';
import { getLoadingSelector } from 'data/selectors/loading';
import ContentBox from 'components/contentBox';
import loadingIcon from 'assets/loading.svg';
import { ISearchResult } from 'ts/interfaces/search/search';

import { 
    SearchResultContainer,
    FlexStartCenter,
    CompanyLogo,
    NameDomainContainer,
    Name,
    Domain,
    Email,
    StatusPoint,
    LoadingContainer,
    EmailContainer,
} from './styles';

const EmailSearchResult: FC = () => {
    const loading = useSelector(getLoadingSelector('search_email'));
    const searchResult: ISearchResult = useSelector(searchSelector);
    const [isCopied, setIsCopied] = useState(false);
    
    if (!Object.keys(searchResult).length && !loading) return <></>;

    const copy = () => {
      if (searchResult?.email?.address ) {
        navigator.clipboard.writeText(searchResult?.email?.address );
        setIsCopied(true);
      }
    };

    return (
        <ContentBox>
            {
                (loading) ? (
                    <LoadingContainer>
                    <img src={loadingIcon} width={50} alt={"loading"} />
                  </LoadingContainer>
                ) : (
            <SearchResultContainer>
                <FlexStartCenter>
                    <CompanyLogo src={`https://logo.clearbit.com/${searchResult?.company?.domain}`} />
                    <NameDomainContainer>
                        <Name>{searchResult?.firstName} {searchResult?.lastName}</Name>
                        <Domain>{searchResult?.company?.domain}</Domain>
                    </NameDomainContainer>
                </FlexStartCenter>
                <EmailContainer>
                  <Tooltip title={isCopied ? 'Copied!' : 'Copy to clipboard'}>
                    <Email copied={isCopied} onClick={copy} onMouseLeave={() => setIsCopied(false)}>{searchResult?.email?.address || 'Not found'}</Email>
                  </Tooltip>
                  { searchResult?.email?.address && <StatusPoint /> }
                </EmailContainer>
            </SearchResultContainer>
                )
            }
        </ContentBox>
    )
} 

export default EmailSearchResult;