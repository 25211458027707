import { IModalState } from 'ts/interfaces/modal/modal';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { EReduxTypes } from 'ts/enums/redux.types';

const initialState: IModalState = {
  type: '',
  visible: false,
  options: {},
}

const modalsReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.TOGGLE_MODAL: {
      const { type, visible, options } = action.data;
      return {
        ...state,
        visible,
        type,
        options: visible && options ? options : {},
      };
    }
    default:
      return state;
  }
}

export default modalsReducer;
