import styled from 'styled-components';
import { SettingPageHeader, SettingPageDescription } from '../account/styles';
import { AiOutlinePlus } from 'react-icons/ai';

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6eaee;
  margin: 20px 0;
`;

export const SectionHeader = styled(SettingPageHeader)`
  font-size: 16px;
  font-weight: 550;
`;

export const SectionDescription = styled(SettingPageDescription)`
  font-size: 13px;
`;

export const SectionItem = styled.div`
  display: flex;
  width: 100%;

  > div {
    width: 50%;
    .ant-radio-inner:after {
      background-color: #5766ec;
    }

    .ant-radio-checked:after {
      border: 1px solid #5766ec;
    }

    .ant-radio-checked .ant-radio-inner  {
      border-color: #5766ec;
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      border-color: #5766ec;
      box-shadow: 0 0 0 2px rgb(87 102 236 / 20%)
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #5766ec;
    }
  }
`;

export const SmallText = styled(SectionDescription)``;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const CardTitle = styled.span`
  font-size: 12px;
  font-weight: 550;
  color: #40413f;
`;

export const ExpirationDate = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #919191;
`;

export const EditCard = styled.span`
   font-size: 12px;
   font-weight: 500;
   color: #919191;
   > span {
    font-weight: 600;
    cursor: pointer;
    color: #5766ec;
   }
`;

export const CardItem = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 10px;
  border: 1px solid ${({ selected }) => (selected ? '#9da6ffc0' : '#e6eaee')};
  border-radius: 10px;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: ${({ selected }) => (selected ? 'rgba(186,193,247, 0.3)' : 'none')};
  > div {
    display: flex;
  }

  ${CardTitle}, ${ExpirationDate}, ${EditCard} {
    color: ${({ selected }) => (selected ? '#5766ec' : 'none')};
  }

  > div > img {
    background-color: #fff;
    height: 50px;
    border-radius: 20%;
    border: 1px solid #e6eaee;
  }
`;

export const AddNewPaymentMethod = styled.span`
   font-size: 14px;
   font-weight: 500;
   color: #919191;
   display: flex;
   align-items: center;
   cursor: pointer;
`;

export const PlusIcon = styled(AiOutlinePlus)`
  margin-right: 5px;
  height: 20px;
  width: auto;
`;

export const CouponContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    flex: 0.6;
  }

  button {
    min-height: 44px;
    border-radius: 3px;
    flex: 0.3;
  }

  @media (max-width: 1249px) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      flex: 1;
    }
    button {
      flex: 1;
      margin-top: 10px;
    }
  }
`;
