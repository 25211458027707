import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const getApplicationList = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_APPLICATIONS_LIST,
    payload: {
      method: 'GET',
      endpoint:  `application`
    },
    components: ['applications'],
  }
  return action;
}

export const addNewApplication = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.ADD_NEW_APPLICATION,
    payload: {
      method: 'POST',
      endpoint:  `application`
    },
  }
  return action;
}

export const deleteApplication = (applicationId: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_APPLICATION,
    payload: {
      method: 'DELETE',
      endpoint:  `application/${applicationId}`
    },
    forwardedData: { applicationId }
  }
  return action;
}