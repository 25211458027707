import { toast } from 'react-toastify';
import CompleteOrderNotification from 'components/completeOrderNotification';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

const initialState: ISubscription | {} = {};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription | {} => {
  switch (action.type) {
    case EReduxTypes.GET_USER_SUBSCRIPTION: {
      return {
        status: action?.payload?.status,
        createdAt: action?.payload?.createdAt,
        updatedAt: action?.payload?.createdAt,
        plan: {
            _id: action?.payload?.plan?._id,
            name: action?.payload?.plan?.name,
            price: action?.payload?.plan?.price,
            credits: action?.payload?.plan?.credits,
            isCustom: action?.payload?.plan?.isCustom,
            coupon: action?.payload?.plan?.coupon,
        }
      };
    }
    case EReduxTypes.ORDER_COMPLETE: {
      const status = action?.payload?.status;
      switch(status) {
        case 'Approved': {
          const plan = action?.payload?.plan?.name;
          toast.success(CompleteOrderNotification(plan), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          break;
        }
        case 'Declined': {
          toast.error("Your payment has been declined.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          break;
        }
        case 'Pending': {
          toast.info("Your payment is being processed. It may take several minutes.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          break;
        }
        default: {
          break;
        }
      }
      return state;
    }

    case EReduxTypes.SUBSCRIPTION_UPDATE: {
      return {
        status: action?.payload?.status,
        createdAt: action?.payload?.createdAt,
        updatedAt: action?.payload?.createdAt,
        plan: {
            _id: action?.payload?.plan?._id,
            name: action?.payload?.plan?.name,
            price: action?.payload?.plan?.price,
            credits: action?.payload?.plan?.credits,
            isCustom: action?.payload?.plan?.isCustom,
            coupon: action?.payload?.plan?.coupon,
            features: action?.payload?.plan?.features,
        }
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;