import {
  IUiPlan
} from '../interfaces/plan/plan'

const Plans: IUiPlan[] = [
    {
        name: 'Hobby',
        price: 39,
        features: [
            '1,000 credits per month',
            'Bulk lead search',
            'Email verification',
            'Usage analytics',
        ],
        disabledFeatures: [
            'Team management',
            'Chat support'
        ],
        id: "6364197c60473bfcda48838e"
    },
    {
        name: 'Basic',
        price: 99,
        features: [
            '5,000 credits per month',
            'Bulk lead search',
            'Email verification',
            'Usage analytics',
            'Team management'
        ],
        disabledFeatures: [
            'Chat support',
        ],
        id: "6364197c60473bfcda48838f",
    },
    {
        name: 'Pro',
        price: 189,
        features: [
            '20,000 credits per month',
            'Bulk lead search',
            'Email verification',
            'Usage analytics',
            'Chat support',
            'Team management'
        ],
        id: "6364197c60473bfcda488390"
    },
    {
        name: 'Ultra',
        price: 369,
        features: [
            '50,000 credits per month',
            'Bulk lead search',
            'Usage analytics',
            'Email verification',
            'Chat support',
            'Team management'
        ],
        id: "6364197c60473bfcda488391"
    }
];

export default Plans;