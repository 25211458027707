import styled from 'styled-components';

const ACTIVE_COLOR = "#1890ff";
const DISABLED_COLOR = "rgba(0, 0, 0, 0.25)";
const TEXT_COLOR = "#1f1f1f";

export const TypeIconContainer = styled.div<{active?: boolean}>`
    width: 120px;
    height: 120px;
    border: ${(props => props.active ? '3px' : '2px')} solid ${(props => props.active ? ACTIVE_COLOR : DISABLED_COLOR)};
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    color: ${(props => props.active ? ACTIVE_COLOR : DISABLED_COLOR)};
    font-size: 35px;
    cursor: pointer;
    transition: all 0.05s ease-in-out 0s;

    &:active {
        transform: scale(0.9);
    }
`;

export const TypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 25px;
`;

export const TypeTitle = styled.span<{active?: boolean}>`
    margin-top: 10px;
    font-size: 18px;
    color: ${(props => props.active ? ACTIVE_COLOR : TEXT_COLOR)};
    transition: all 0.05s ease-in-out 0s;
    letter-spacing: 1px;
`;