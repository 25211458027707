import { ICompaniesState, ISuggestedCompany } from 'ts/interfaces/company/suggested.company';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ICompany } from 'ts/interfaces/company/company';

const initialState: ICompaniesState = {};

const companyReducer = (state = initialState, action: IReduxAction): ICompaniesState => {
  switch (action.type) {
    case EReduxTypes.COMPANY_AUTOCOMPLETE: {
      const payload = action?.payload;
      const suggestedCompanies = payload as ISuggestedCompany[];
      const result = suggestedCompanies.reduce((acc: ISuggestedCompany[], currentValue: ISuggestedCompany) => {
        if (([...acc, ...action.forwardedData.excludedDomains.map((value: string) => ({ domain: value }))]).find(item => item.domain === currentValue.domain)) return acc;
        else return [...acc, currentValue];
      }, []);
      return {
        ...state,
        suggestedCompanies: result
      }
    }
    case EReduxTypes.LOAD_LEAD_COMPANIES: {
      return {
        ...state,
        loadedCompanies: action?.payload as ICompany[]
      }
    }
    case EReduxTypes.SET_LEAD_COMPANIES: {
      return {
        ...state,
        loadedCompanies: action?.data
      }
    }
    default:
      return state;
  }
};

export default companyReducer;
