import styled from 'styled-components';

export const Description = styled.div`
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const EmailInput = styled.input`
    line-height: 19px;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(230, 234, 238);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 14px 13px 13px 14px;
    transition: all 1s ease 0s;

    &:hover, &:active, &:focus  {
      border: 1px solid rgb(87, 102, 236);
      outline: rgb(87, 102, 236);
      transition: all 0.2s ease-in-out 0s;
    }

    &:disabled {
      background: rgb(245, 247, 248);
      transition: all 1s ease 0s;
    }
`;

export const ButtonContainer = styled.div`
    flex-basis: 30%;
    margin-left: 20px;
`;

export const VerificationForm = styled.div`
  display: flex;
`;