import { capitalize } from 'lodash';
import { IUsageHistory } from 'ts/interfaces/usage/usage.history';
import moment from 'moment';
import EmailCell from 'components/emailCell';

const HistoryTableColumns = [
    {
      Header: 'Type',
      accessor: (originalRow: IUsageHistory) => capitalize(originalRow.type.replace('_', ' ')),
    },
    {
      Header: 'Email',
      accessor: (originalRow: IUsageHistory) => {
          const email = originalRow?.email?.value;
          const status = originalRow?.email?.status;

          return <EmailCell email={email} status={status} />
      },
    },
    {
      Header: 'Cost',
      accessor: 'cost',
    },
    {
      Header: 'Date',
      accessor: (originalRow: IUsageHistory) => moment(originalRow.createdAt).fromNow(),
    },
];

export default HistoryTableColumns;
