import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IPagination } from 'ts/interfaces/common/pagination';

export const getOrderList = (pagination: IPagination): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_ORDER_LIST,
    payload: {
      method: 'GET',
      endpoint: `billing/orders?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`,
    },
    components: ['order_list']
  }
  return action;
};