import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
const fadeInAnimation = keyframes`${fadeIn}`;

export const DraggerIcon = styled.img`
    width: 50px;
    margin-bottom: 15px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`; 

export const FileResultContainer = styled.div`
    margin-top: 20px;
    display: flex;
    animation: 300ms ${fadeInAnimation};
    justify-content: space-between;
    padding-right: 10px;
`;

export const FileIcon = styled.img`
    width: 46px;
`;

export const FileData = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin-left: 10px;
`;

export const FileName = styled.span`
    font-weight: bold;
`;

export const FileSize = styled.span`
    
`;

export const ClearFileIcon = styled.div`
    font-size: 18px;
    color: #636363;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.6;
    }
`;

export const CenteredFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;