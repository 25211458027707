import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { INavItemProps } from 'ts/interfaces/components/nav.item.props';

const fadeInAnimation = keyframes`${fadeIn}`;

export const SettingsContainer = styled.div`
    width: 100%;
    display: flex;

    @media (max-width: 1249px) {
        flex-direction: column;
    }
`;

export const SidebarContainer = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    border-right: 2px solid #f6f6f6;

    @media (max-width: 1249px) {
        width: 100%;
        flex-direction: row;
        border-right: none;
        overflow: scroll;
    }
`;

export const SettingsPageContent = styled.div`
    width: 80%;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: 1249px) {
        width: 100%;
    }
`;

export const SettingsPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1249px) {
        width: 1000px;
    }
`;

export const MenuItem = styled.button<INavItemProps>`
    background: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
    font-size: 16px;
    margin-right: 15px;
    padding: 8px 10px;
    border-radius: 4px;

    will-change: transform;
    transition: transform 200ms ease;

    ${(props) => props.active && 'background: rgb(245,247,248);'}
    ${(props) => props.active && 'color: rgb(87,102,236);'}
    ${(props) => props.active && 'box-sizing: border-box;'}

    &:active {
      transform: scale(0.9);
    }

    &:hover {
        background: rgb(245,247,248);
    }

    svg {
        margin-right: 10px;
        color: #3790ff;
        font-size: 18px;
    }
`;

export const ContentContainer = styled.section`
    width: 100%;
    border: 1px solid #ECECEC;
    padding: 20px;
    position: relative;
    background: white;
    box-shadow: rgb(124 124 149 / 8%) 0px 8px 30px;
    border-radius: 8px;
    margin-top: 20px;
    max-height: 100%;
    overflow-y: scroll;
    padding-bottom: 0;
    animation: 100ms ${fadeInAnimation};
`;

export const SaveButtonContainer = styled.div<{ flex?: number }>`
    flex: ${(props) => props?.flex || 1};

    @media (max-width: 1249px) {
        margin: 10px 0;
        width: 100%;
    }
`;

export const SectionDescriptionContainer = styled.div<{ flex?: number }>`
    display: flex
`;
