import { FC } from 'react';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { BiTrash, BiMinusCircle, BiCheckDouble } from 'react-icons/bi';

// import { setSelectedLeads } from 'data/actions/leads';
import { leadsMetaSelector, selectedLeadsSelector } from 'data/selectors/leads';
import Button from 'components/common/button';

import { 
  ActionsContainer
} from './styles';

interface ActionsBarDTO {
  deleteLeads: unknown,
  onSelectAll?: unknown,
  onDiscardAll?: unknown
}


const ActionsBar: FC<ActionsBarDTO> =
({ deleteLeads, onSelectAll, onDiscardAll }) => {
  const selectedLeads = useSelector(selectedLeadsSelector);
  const { totalItems = 0,  } = useSelector(leadsMetaSelector);

  const handleDelete = () => {
    if (typeof deleteLeads === 'function') deleteLeads(selectedLeads)
  }

  const handleSelectAll = () => {
    if (typeof onSelectAll === 'function') onSelectAll()
  }

  const handleDiscardAll = () => {
    if (typeof onDiscardAll === 'function') onDiscardAll(selectedLeads)
  }

  return (
    <ActionsContainer>
      <span style={{ marginLeft: 10, whiteSpace: 'nowrap', alignSelf: 'center' }}>
        {`(${selectedLeads.length || totalItems} selected)`}
      </span>

      <Tooltip title="Select all">
        <Button type="link" onClick={handleSelectAll}>
          <BiCheckDouble size={30} />
        </Button>
      </Tooltip>

      <Tooltip title="Discard selection">
        <Button type="link" onClick={handleDiscardAll}>
          <BiMinusCircle size={25} />
        </Button>
      </Tooltip>

      <Tooltip title="Delete">
        <Button type="link" onClick={handleDelete} style={{ color: 'red' }}>
          <BiTrash size={25} />
        </Button>
      </Tooltip>
    </ActionsContainer>
  )
}

export default ActionsBar;