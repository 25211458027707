import { createSelector } from 'reselect';

const baseBulkOperationsSelector = (state: any) => state?.bulkOperations;

export const bulkOperationsDataSelector = createSelector(
    baseBulkOperationsSelector,
    (BulkOperations) => BulkOperations?.data || [],
);

export const bulkOperationsMetaSelector = createSelector(
  baseBulkOperationsSelector,
  (BulkOperations) => BulkOperations?.meta || {},
);