import { IDrawerState } from 'ts/interfaces/drawer/drawer';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { EReduxTypes } from 'ts/enums/redux.types';

const initialState: IDrawerState = {
  visible: false,
}

const drawerReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.TOGGLE_DRAWER: {
      const { type, visible } = action.data;
      return {
        ...state,
        visible,
        type,
        data: action?.data?.data
      };
    }

    case EReduxTypes.UPDATE_LEAD: {
      return {
        ...state,
        data: action?.payload
      };
    }
    default:
      return state;
  }
}

export default drawerReducer;
