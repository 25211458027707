import { Dispatch } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const verifyEmail = (email: string) => (dispatch: Dispatch): void => {
  const action: IReduxAction = {
    type: EReduxTypes.VERIFY_EMAIL,
    payload: {
      method: 'GET',
      endpoint: `email/verify?email=${email}`,
    },
    components: ['verification'],
  }
  dispatch(action);
};
