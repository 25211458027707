import { FC } from 'react';
import { Container, Input, Icon } from './styles';
import MailIcon from 'assets/mail.svg';

const EmailInput: FC<any> = ({ onChange, value }) => {
    return (
        <Container>
            <Icon src={MailIcon} />
            <Input placeholder="Email" onChange={onChange} value={value} name="email" />
        </Container>
    )
}

export default EmailInput;