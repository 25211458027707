export enum EReduxTypes {
  COMPANY_AUTOCOMPLETE = 'COMPANY_AUTOCOMPLETE',
  GET_USER_CREDITS = 'GET_USER_CREDITS',
  UPDATE_LOADING_COMPONENT = 'UPDATE_LOADING_COMPONENT',
  SEARCH_EMAIL = 'SEARCH_EMAIL',
  SIGN_UP = 'SIGN_UP',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  FIND_LEADS = 'FIND_LEADS',
  DELETE_LEADS = 'DELETE_LEADS',
  UPDATE_LEAD = 'UPDATE_LEAD',
  UPDATE_LEAD_RELATION = 'UPDATE_LEAD_RELATION',
  PASSWORD_RESET = 'PASSWORD_RESET',
  SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD',
  GET_BULK_OPERATIONS_LIST = 'GET_BULK_OPERATIONS_LIST',
  BULK_OPERATION_STATUS = 'BULK_OPERATION_STATUS',
  CREATE_BULK_OPERATION = 'CREATE_BULK_OPERATION',
  START_BULK_OPERATION = 'START_BULK_OPERATION',
  BULK_OPERATION_CREATE_PROCESSING_FILE = "BULK_OPERATION_WIZARD.CREATE_PROCESSING_FILE",
  BULK_OPERATION_MATCH_COLUMN = "BULK_OPERATION_WIZARD.MATCH_COLUMN",
  BULK_OPERATION_CLEAR_COLUMN_MATCHING = "BULK_OPERATION_WIZARD.CLEAR_COLUMN_MATCHING",
  BULK_OPERATION_WIZARD_SET_TYPE = "BULK_OPERATION_WIZARD.SET_TYPE",
  BULK_OPERATION_WIZARD_FLUSH_STATE = "BULK_OPERATION_WIZARD.FLUSH_STATE",
  INIT_EVENT_STREAM = "INIT_EVENT_STREAM",
  GET_BULK_OPERATION_RESULT = "GET_BULK_OPERATION_RESULT",
  DELETE_BULK_OPERATION = "DELETE_BULK_OPERATION",
  GOOGLE_AUTH = 'GOOGLE_AUTH',
  GET_USAGE_HISTORY = 'GET_USAGE_HISTORY',
  GET_USAGE_ANALYTICS = 'GET_USAGE_ANALYTICS',
  GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
  GET_USER = 'GET_USER',
  GET_USER_SETTINGS = 'GET_USER_SETTINGS',
  DELETE_USER = 'DELETE_USER',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  APPLY_COUPON = 'APPLY_COUPON',
  ORDER_COMPLETE = 'ORDER_COMPLETE',
  SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE',
  GET_APPLICATIONS_LIST = 'GET_APPLICATIONS_LIST',
  ADD_NEW_APPLICATION = 'ADD_NEW_APPLICATION',
  DELETE_APPLICATION = 'DELETE_APPLICATION',
  GENERATE_PURCHASE_URL = 'GENERATE_PURCHASE_URL',
  SET_SELECTED_LEADS = 'SET_SELECTED_LEADS',
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  LOAD_LEAD_COMPANIES = 'LOAD_LEAD_COMPANIES',
  SET_LEAD_COMPANIES = 'SET_LEAD_COMPANIES',
  GET_ORDER_LIST = 'GET_ORDER_LIST',
}
  