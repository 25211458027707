import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/common/button';
import { updatePassword } from 'data/actions/user';
import { isGoogleUser } from 'data/selectors/user';
import { SettingPageHeader, Container, SettingPageDescription, InputContainer, InputLabel, BaseInput, AccountSettingsContainer } from '../account/styles';
import openNotification from 'components/common/commonNotification';
import { INotification } from 'ts/interfaces/common/notification';
import { SaveButtonContainer } from '../styles';

const PasswordSettingsPage: FC = () => {
    const dispatch = useDispatch();
    const [currPassword, setCurrPassword] = useState<string|void>();
    const [newPassword, setNewPassword] = useState<string|void>();
    const [repPassword, setRepPassword] = useState<string|void>();
    const [isDisabled, setIsDisabled] = useState<any>(true);
    const googleUser = useSelector(isGoogleUser);

    useEffect(() => {
      setIsDisabled((!currPassword && !googleUser) || !newPassword || !repPassword);
    }, [currPassword, newPassword, repPassword, googleUser]);

    const clearFields = () => {
      setCurrPassword();
      setNewPassword();
      setRepPassword();
    }

    const handleChange = () => {
      let notification;
      if (newPassword && newPassword?.length < 8) {
        notification = {
          message: 'Failed to update password',
          description: 'Password should be equal or longer than 8 characters',
          type: 'error',
        };
      }

      if (newPassword !== repPassword) {
        notification = {
          message: 'Failed to update password',
          description: 'Repeated password do not match new password',
          type: 'error',
        };
      }

      if (notification) {
        openNotification(notification as INotification);
      } else if (newPassword) {
        dispatch(updatePassword(
          currPassword,
          newPassword, 
          () => {
            openNotification({
              message: 'Success!',
              description: 'Password was successfully updated',
              type: 'success',
            });
            clearFields();
          },
          (msg: string) => {
            openNotification({
              message: 'Failed to update password',
              description: msg,
              type: 'error',
            });
          })
        );
      }
    };

    return (
        <Container>
            <SettingPageHeader>Password</SettingPageHeader>
            <SettingPageDescription>Here you can change the Email address, Name and Time Zone.</SettingPageDescription>
        
            <AccountSettingsContainer>
                {!googleUser && <InputContainer>
                    <InputLabel>Current Password</InputLabel>
                    <BaseInput value={currPassword || ''} onChange={(e) => setCurrPassword(e.target.value)} type="password" name="current-password" />
                </InputContainer>}

                <InputContainer>
                    <InputLabel>New Password</InputLabel>
                    <BaseInput value={newPassword || ''} onChange={(e) => setNewPassword(e.target.value)} type="password" />
                </InputContainer>

                <InputContainer>
                    <InputLabel>Confirm Password</InputLabel>
                    <BaseInput value={repPassword || ''} onChange={(e) => setRepPassword(e.target.value)} type="password" />
                </InputContainer>
            </AccountSettingsContainer>
            <AccountSettingsContainer>
                <SaveButtonContainer flex={0.2}>
                    <Button disabled={isDisabled} onClick={handleChange} style={{ maxHeight: 44, borderRadius: 3 }}>Change</Button>
                </SaveButtonContainer>
            </AccountSettingsContainer>
        </Container>
    )
}

export default PasswordSettingsPage;
