import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ChartContainer = styled.div`
  background: white;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

