import { IVerificationResult } from 'ts//interfaces/verification/verification.result'
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IVerificationResult = {};

const verificationReducer =  (state = initialState, action: IReduxAction): IVerificationResult => {
  switch (action.type) {
    case EReduxTypes.VERIFY_EMAIL: {
      const verificationResult = action.payload as IVerificationResult
      return verificationResult;
    }
    default:
      return state;
  }
};

export default verificationReducer;