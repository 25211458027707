import styled from "styled-components";
import { SectionTitle } from 'components/common/styles';
import previewImg from 'assets/video-preview.jpg';
import { PlayCircleOutlined } from '@ant-design/icons';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding-bottom: 35px;
    
`;

export const Title = styled(SectionTitle)`
    text-align: center;
    margin-bottom: 15px;
`;  

export const ButtonContainer = styled.div`
    margin-top: 20px;
    width: 250px;
`;

export const UploadIcon = styled.span`
    font-size: 20px;
    margin-right: 5px;
`;

export const Description = styled.p`
    font-size: 16px;
    margin: 0;
`;


export const VideoContainer = styled.div`
    max-width: 550px;
    min-width: 350px;
    width: 60vw;
    height: 318px;
    margin-top: 30px;
    border: 3px solid #5766ec;
    border-radius: 5px;
`;

export const Preview = styled.div`
    max-width: 544px;
    min-width: 344px;
    width: 59.2vw;
    height: 312px;
    background: no-repeat center/100% url(${previewImg});
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`;

export const PlayIcon = styled(PlayCircleOutlined)`
  color: rgb(255 255 255 / 80%);
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  svg {
    width: 100px;
    height: auto;
  }
`;
