import { IBulkOperationsState } from 'ts/interfaces/bulkOperations/bulkOperations.state';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IBulkOperation, IBulkOperationProcessingStatus } from 'ts/interfaces/bulkOperations/bulkOperation';

let initialState: IBulkOperationsState;

const bulkOperationsReducer = (state = initialState || {}, action: IReduxAction): IBulkOperationsState => {
  switch (action.type) {
    case EReduxTypes.GET_BULK_OPERATIONS_LIST: {
      return action.payload as IBulkOperationsState;
    }
    case EReduxTypes.CREATE_BULK_OPERATION: {
      const bulkOperation = action.payload as IBulkOperation;
      const data = [...state.data];
      data.unshift(bulkOperation);
      return {
        ...state,
        data
      }
    }
    case EReduxTypes.BULK_OPERATION_STATUS: {
      const bulkOperations: IBulkOperation[] = [...state?.data];
      const processingStatus = action?.payload as IBulkOperationProcessingStatus;
      const bulkOperationIndex = bulkOperations.findIndex((bulkOperation: IBulkOperation) => bulkOperation._id === processingStatus?.id)
      if (bulkOperationIndex > -1) {
        bulkOperations[bulkOperationIndex].processingStatus = {
          ...bulkOperations[bulkOperationIndex].processingStatus,
          ...processingStatus
        };
        bulkOperations[bulkOperationIndex].status = processingStatus.status;
      }
      return {
        ...state,
        data: bulkOperations
      };
    }
    case EReduxTypes.DELETE_BULK_OPERATION: {
      const deletedBulkOperation = action?.payload?._id;
      return {
        ...state,
        data: state?.data?.filter((bulkOperation) => bulkOperation._id !== deletedBulkOperation)
      }
    }
    default:
      return state;
  }
};

export default bulkOperationsReducer;