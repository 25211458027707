import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Button from 'components/common/button';
import CommonSelect from 'components/common/commonSelect';
import { userFirstName, userLastName, userTimezone } from 'data/selectors/user';
import { updateUser, deleteUser, signOut } from 'data/actions/user';
import { getLoadingSelector } from 'data/selectors/loading';
import { toggleModal } from 'data/actions/modals';
import { EModalTypes } from 'ts/enums/modal.types';
import { 
  SettingPageHeader,
  Container,
  SettingPageDescription,
  InputContainer,
  InputLabel,
  BaseInput,
  AccountSettingsContainer,
  DeleteButton,
} from './styles';
import { SaveButtonContainer } from '../styles';
import { FlexColumnContainer, FlexRowContainer } from 'components/common/styles';

const AccountSettingsPage: FC = () => {
    const dispatch = useDispatch();
    const currentFirstName = useSelector(userFirstName);
    const currentLastName = useSelector(userLastName);
    const currentTimezone = useSelector(userTimezone);
    const [firstName, setFirstName] = useState(currentFirstName);
    const [lastName, setLastName] = useState(currentLastName);
    const [timezone, setTimezone] = useState(currentTimezone);
    const [updatedFields, setUpdatedFields] = useState<any[]>([]);
    const [timezones, setTimezones] = useState<{ value: string, label: string }[]>([]);
    const [saved, setSaved] = useState(false);
    const loading = useSelector(getLoadingSelector('update_user'));

    useEffect(() => {
      const zones = moment.tz.names();
      setTimezones(zones.map((item) => ({
        value: item, label: `(GMT ${moment.tz(item).format('Z')}) ${item}`
      })))
    }, []);

    useEffect(() => {
      const updated = [];
      if (firstName !== currentFirstName) {
        updated.push({ firstName });
      }
      if (lastName !== currentLastName) {
        updated.push({ lastName });
      }
      if (timezone !== currentTimezone) {
        updated.push({ timezone });
      }
      setUpdatedFields(updated);
    }, [firstName, lastName, timezone, currentFirstName, currentLastName, currentTimezone]);

    const handleUpdate = () => {
      if (updatedFields.length > 0) {
        dispatch(updateUser(updatedFields, () => setSaved(true)));
      }
    };

    const handleDelete = () => {
      dispatch(
        toggleModal(
          EModalTypes.CONFIRMATION,
          true,
          { 
            title: 'Delete account',
            description: 'All subscriptions and data associated with your account will be removed. Are you sure you want to do this?',
            buttonAcceptText: 'Yes, delete my account',
            buttonDeclineText: 'Cancel',
            acceptAction: () => {
              dispatch(deleteUser(() => {
                dispatch(toggleModal(EModalTypes.CONFIRMATION, false));
                dispatch(signOut());
              }));
            },
          },
        )
      );
    }

    return (
        <Container>
            <FlexColumnContainer>
              <SettingPageHeader>Your Account</SettingPageHeader>
              <SettingPageDescription>Here you can change the Email address, Name and Time Zone.</SettingPageDescription>
            </FlexColumnContainer>

            <AccountSettingsContainer>
                <InputContainer>
                    <InputLabel>First Name</InputLabel>
                    <BaseInput value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Your first name..."/>
                </InputContainer>

                <InputContainer>
                    <InputLabel>Last Name</InputLabel>
                    <BaseInput value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="And last name..."/>
                </InputContainer>
            </AccountSettingsContainer>

            <AccountSettingsContainer>
                <InputContainer style={{ flex: 4 }}>
                    <InputLabel>Timezone</InputLabel>
                    <CommonSelect
                      placeholder="Select timezone"
                      options={timezones}
                      value={timezone}
                      onChange={(value) => setTimezone(value)}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                </InputContainer>
                <SaveButtonContainer>
                    <Button style={{ maxHeight: 44, borderRadius: 3 }} disabled={!updatedFields.length} loading={loading} onClick={handleUpdate}>
                      {saved && updatedFields.length === 0 ? 'Saved' : 'Save'}
                    </Button>
                </SaveButtonContainer>
            </AccountSettingsContainer>
            <SettingPageHeader style={{ marginTop: 20 }}>Delete Account</SettingPageHeader>
            <SettingPageDescription style={{ width: '100%' }}><FlexRowContainer>We do our best to give you a great experience - we'll be sad to see you leave us. <DeleteButton onClick={handleDelete}>Delete Account</DeleteButton></FlexRowContainer></SettingPageDescription>
        </Container>
    )
}

export default AccountSettingsPage;
