import styled from "styled-components";

export const ApiKeyContainer = styled.div`
    background: #f7f8fa;
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    border: 1px solid #dfe2e5;
    cursor: pointer;
    font-family: monospace;
    display: inline-block;
    padding: 3px 10px;
    color: #33475b;
    white-space: nowrap;

    @media (max-width: 1249px) {
        overflow: scroll;
        width: 35vw;
    }
`;

export const ApiKeyHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    button {
        width: 170px;
    }

    @media (max-width: 1249px) {
        flex-direction: column;
        
        button {
            width: 100%;
        }
    }
`;

export const ApiKeyActionsContainer = styled.div`
    display: flex;
    align-items: center;

    button {
        margin: 0 5px;
    }
`;

export const ApiKeyTablesContainer = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;

    @media (max-width: 1249px) {
        th:nth-child(2) {
            display: none;
        }

        td:nth-child(2) {
            div:first-child {
                div:first-child {
                    display: none;
                }
            }
        }
    }
`;
