import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from 'components/common/styles';
import BulkOperationsTable from 'components/tables/bulkOperationsTable';
import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import { HeaderContainer, ButtonContainer, UploadIcon }from './styles';
import CreateBulkOperation from 'components/modals/createBulkOperationModal';
import { EModalTypes } from 'ts/enums/modal.types';
import { toggleModal } from 'data/actions/modals';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { getBulkOperationsList } from 'data/actions/bulkOperations';
import BulkOperationsEmptyState from 'components/emptyStates/bulkOperationsEmptyState';
import { bulkOperationsDataSelector } from 'data/selectors/bulkOperations';
import { IBulkOperation } from 'ts/interfaces/bulkOperations/bulkOperation';
import { getLoadingSelector } from 'data/selectors/loading';

const BulkOperationsPage: FC = () => {
  const dispatch = useDispatch();
  const [isComponentInit, setIsComponentInit] = useState<boolean>(false);
  const bulkOperations: IBulkOperation[] = useSelector(bulkOperationsDataSelector);
  const isLoading = useSelector(getLoadingSelector('bulk_operations_list'))

  useEffect(() => {
    dispatch(getBulkOperationsList({ pageNumber: 0, pageSize: 10 }))
    setTimeout(() => setIsComponentInit(true), 1);
  }, [dispatch]);

  if (!isComponentInit || (isLoading && !bulkOperations?.length)) return <></>
  return (
    <>
      <CreateBulkOperation />
      {
        !bulkOperations?.length
        ? <BulkOperationsEmptyState />
        : (
            <>
              <HeaderContainer>
                <SectionTitle>Bulk operations</SectionTitle>
                <ButtonContainer>
                  <Button size={ButtonHeight.MEDIUM} onClick={()=> dispatch(toggleModal(EModalTypes.CREATE_BULK_OPERATIONS,
                    true))}> <UploadIcon>
                      <AiOutlineCloudUpload />
                    </UploadIcon> Upload file</Button>
                </ButtonContainer>
              </HeaderContainer>
              <BulkOperationsTable />
            </>
          )
      }
    </>
  )
}

export default BulkOperationsPage;