import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from 'data/actions/verification';
import { getLoadingSelector } from 'data/selectors/loading';
import Button from 'components/common/button';
import ContentBox from 'components/contentBox';
import { 
    Description,
    EmailInput,
    VerificationForm,
    ButtonContainer
} from './styles';

export const EmailVerificationInput: FC = () => {
    const loading = useSelector(getLoadingSelector('verification'));
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');

    console.log('loading--->', loading)

    return (
        <>
                <ContentBox>
                    <Description>Enter an email address to verify its deliverability</Description>
                    <VerificationForm>
                        <EmailInput placeholder="jon.snow@company.com" disabled={loading} value={email} onChange={(e) => setEmail(e?.target?.value)}/>
                        <ButtonContainer>
                            <Button onClick={() => dispatch(verifyEmail(email))} disabled={loading || !email} >Check</Button>
                        </ButtonContainer>
                    </VerificationForm>
                </ContentBox>
        </>
    )
}

export default EmailVerificationInput;