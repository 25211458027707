import { upperFirst } from "lodash";

const CompleteOrderNotification = (plan?: string) => {
    return (
        <>
            <div>Your order has been received!</div>
            { plan && <div> <b>{upperFirst(plan)}</b> plan was activated 🎉</div> }
        </>
    )
}

export default CompleteOrderNotification;