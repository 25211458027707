import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { EModalTypes } from 'ts/enums/modal.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { BsX } from 'react-icons/bs';
import {
  Title,
  Header,
  ControlButton,
} from '../baseModal/styles';
import {
  ButtonsContainer,
  AcceptButton,
  DeclineButton,
  ModalContainer,
  DescriptionContainer 
} from './styles';

const ConfirmationModal: FC = () => {
  const dispatch = useDispatch();
  const { title, description, buttonAcceptText, buttonDeclineText, acceptAction } = useSelector(modalOptionsSelector);
  const { visible } = useSelector(modalsSelector);
  return (
    <Modal
      open={visible}
      destroyOnClose
      footer={null}
      closable={false}
      width={520}
    >
      <Header>
        <ControlButton onClick={() => dispatch(toggleModal(EModalTypes.CREATE_BULK_OPERATIONS, false))}>
            <BsX />
        </ControlButton>
      </Header>
      <ModalContainer>
        <Title>
          {title}
        </Title>
        <DescriptionContainer>
          {description}
        </DescriptionContainer>
        <ButtonsContainer>
          <AcceptButton outline onClick={acceptAction} >{buttonAcceptText}</AcceptButton>
          <DeclineButton onClick={() => dispatch(toggleModal(EModalTypes.CREATE_BULK_OPERATIONS, false))}>{buttonDeclineText}</DeclineButton>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
