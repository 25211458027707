import { FC, useState } from 'react';
import { Tooltip } from 'antd';
import { BsCheck } from 'react-icons/bs';

//** Styles */
import { CodeBox, Code, Container } from './styles';
import { FlexRowContainer } from 'components/common/styles';

const ExampleInputBox: FC<{ value: string }> = ({ value }) => {
  const [isCopied, setIsCopied] = useState(false);
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  }
      
  return (
    <Container>
      <Tooltip 
        title={!isCopied ? 'Click to copy' : <FlexRowContainer><BsCheck size={22} color='#24a522' /> Copied </FlexRowContainer>}
        onOpenChange={(val) => val && setIsCopied(false)}
      >
        <CodeBox onClick={copyToClipboard}>
          <Code>{value}</Code>
        </CodeBox>
      </Tooltip>
    </Container>
  );
}

export default ExampleInputBox;