import { FC } from 'react';
import { ButtonProps } from 'ts/types/button.props';
import loadingIcon from 'assets/loading.svg';
import { ButtonContainer, LinkButtonContainer } from './styles';

const Button: FC<ButtonProps | any> = ({ type = 'common', children, onClick, style, disabled, loading, size, ...props }) => {
    return (type === 'link') ? (<LinkButtonContainer {...props} onClick={onClick} style={style} disabled={disabled || loading}>{loading ? <img src={loadingIcon} width={20} alt={"loading"} /> : children}</LinkButtonContainer>) : 
    (
        <ButtonContainer {...props} onClick={onClick} style={style} disabled={disabled || loading} size={size}>
            {loading ? <img src={loadingIcon} width={20} alt={"loading"} /> : children}
        </ButtonContainer>
    )
    
}

export default Button;