import { FC } from 'react';
import { SectionTitle } from 'components/common/styles';
import EmailVerificationInput from 'layouts/emailVerificationInput';
import EmailVerificationResult from 'layouts/emailVerificationResult';

import {
  Container
} from './styles';

const VerificationPage: FC = () => {
  return (
    <Container>
      <SectionTitle>Email Verification</SectionTitle>
      <EmailVerificationInput />
      <EmailVerificationResult />
    </Container>
  )
}

export default VerificationPage;