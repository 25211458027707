import styled, { keyframes } from "styled-components";
import { fadeIn } from 'react-animations';
const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
    width: 240px;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    border-right: 1px solid #e6eaee;
    animation: 200ms ${fadeInAnimation};
`;

export const Label = styled.div`
    display: flex;
    justify-content: space-between;
`;