import { FC } from 'react';
import { Progress } from 'antd';
import { IBulkOperationProcessingStatus } from 'ts/interfaces/bulkOperations/bulkOperation';
import { EBulkOperationStatus } from 'ts/enums/bulkOperation';
import { ProgressContainer } from './styles';

const BulkOperationProgressBar: FC<{ processingStatus?: IBulkOperationProcessingStatus }> = ({ processingStatus }) => {
    if (!processingStatus) return  <div style={{ width: 170 }}></div>
    const percent = Math.ceil((processingStatus.processedLines / processingStatus.totalLines) * 100);
    
    let status: "active" | "success" | "exception" | "normal";

    switch(processingStatus.status) {
        case (EBulkOperationStatus.PROCESSING):
            status = "active";
            break;
        case (EBulkOperationStatus.DONE):
            status = "success";
            break;
        case (EBulkOperationStatus.FAILED):
            status = "exception";
            break;
        case (EBulkOperationStatus.NEW):
        default:
            status = "normal";
            break;
    }

    return (
        <ProgressContainer>
            <Progress percent={percent} size="small" status={status}/>
        </ProgressContainer>
    )
}

export default BulkOperationProgressBar;