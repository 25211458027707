import moment from 'moment';
import { upperFirst } from 'lodash';
import { IOrder } from 'ts/interfaces/order/order';
import { Dot } from 'components/common/styles';
import { Cell } from './styles';

const OrderTableColumns = [
    {
      Header: 'Order',
      accessor: (originalRow: IOrder) => `${upperFirst(originalRow.plan.name)} Plan - ${upperFirst(originalRow.regularMode)} payment`,
    },
    {
      Header: 'Amount',
      accessor: (originalRow: IOrder) => `$${originalRow.amount}`,
    },
    {
      Header: 'Date',
      accessor: (originalRow: IOrder) => moment(originalRow.createdAt).format('MMM D, YYYY'),
    },
    {
      Header: 'Status',
      accessor: (originalRow: IOrder) => <Cell><Dot error={originalRow.transactionStatus !== 'Approved'}/> {originalRow.transactionStatus} </Cell>,
    }
];

export default OrderTableColumns;
