import { createSelector } from 'reselect';

const baseLeadsSelector = (state: any) => state?.leads;

export const leadsDataSelector = createSelector(
    baseLeadsSelector,
    (leads) => leads?.data || [],
);

export const leadsMetaSelector = createSelector(
  baseLeadsSelector,
  (leads) => leads?.meta || {},
);

export const selectedLeadsSelector = createSelector(
  baseLeadsSelector,
  (leads) => leads?.selectedLeads || [],
);