import styled from 'styled-components';

const ACTIVE_COLOR = "#1890ff";
const TEXT_COLOR = "#1f1f1f";

export const StepsContainer = styled.div`
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StepItem = styled.div<{ done?: boolean, active?: boolean }>`
    height: 2px;
    flex: 1;
    background: ${props => props?.done ? ACTIVE_COLOR : "#f0f0f0"};
    position: relative;
    transition: all 0.3s ease-in-out 0s;


    &:before {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: ${props => (props?.done || props?.active) ? ACTIVE_COLOR : "#bfbfbf"};
        outline: 3px solid white;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: -4px;
        transition: all 0.3s ease-in-out 0s;
    }

    &:last-child {
        &:after {
            content: "";
            display: block;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: ${props => props?.done ? ACTIVE_COLOR : "#bfbfbf"};
            outline: 3px solid white;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: -4px;
            transition: all 0.3s ease-in-out 0s;
        }
    }

    span {
        width: 100%;
        display: block;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 10px;
        color: ${TEXT_COLOR};
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.7;
    }
`;