import { FC, useCallback, useEffect, useState } from "react";
import { Avatar } from "antd";
import { BiCheck } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedLeads } from "data/actions/leads";
import { selectedLeadsSelector } from "data/selectors/leads";
import { ILead } from "ts/interfaces/leads/lead";
import getAvatarColor from "helpers/getAvatarColor";
import { AvatarCheckboxContainer, EmptyCheckbox, SelectedCheckbox } from "./styles";

const AvatarCheckbox: FC<{ originalRow: ILead }> = ({ originalRow }) => {
  const dispatch = useDispatch();
  const [checkboxVisible, toggleCheckboxVisible] = useState(false);
  const selectedLeads = useSelector(selectedLeadsSelector);
  const isSelected = selectedLeads.includes(originalRow._id);

  const onMouseOver = useCallback(() => {
    if (!isSelected) toggleCheckboxVisible(true);
  }, [isSelected]);

  const onMouseLeave = useCallback(() => {
    if (!isSelected) toggleCheckboxVisible(false);
  }, [isSelected]);

  const onCheckboxClick = useCallback(() => {
    if (!isSelected) dispatch(setSelectedLeads([...selectedLeads, originalRow._id]));
    else dispatch(setSelectedLeads(selectedLeads.filter((id: string) => id !== originalRow._id)));
  }, [dispatch, selectedLeads, isSelected, originalRow]);

  useEffect(() => {
    if (!selectedLeads?.length) toggleCheckboxVisible(false);
    else toggleCheckboxVisible(true);
  }, [selectedLeads]);

  useEffect(() => {
    if (isSelected) toggleCheckboxVisible(true);
  }, [isSelected]);


  return (
    <AvatarCheckboxContainer
      onMouseEnter={onMouseOver}
      onClick={onCheckboxClick}
      onMouseLeave={onMouseLeave}
    >
      { checkboxVisible ?
      (
        <>
          { isSelected ?
            <SelectedCheckbox>
              <BiCheck size={28} color={'white'} />
            </SelectedCheckbox>
            :
            <EmptyCheckbox />
          }
        </>
      ) : (
        <Avatar style={{ backgroundColor: getAvatarColor(originalRow.firstName as string, originalRow.lastName as string), verticalAlign: 'middle' }}>
          {`${originalRow.firstName?.[0]}${originalRow.lastName?.[0]}`}
        </Avatar>
      )}
    </AvatarCheckboxContainer>
  )
}

export default AvatarCheckbox;
