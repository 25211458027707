import styled  from 'styled-components';
import { Menu } from 'antd';

export const MenuItem = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px;
    min-width: 170px;
    font-size: 16px;
    cursor: pointer;

    background: ${props => props.disabled ? '#f6f6f6' : 'none'};
    opacity: ${props => props.disabled ? 0.6 : 1};

    &:hover {
        background: #f6f6f6;
    }

    span {
        margin-left: 10px;
    }
`;

export const MoreButtonContainer = styled.img`
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    width: 35px;
    will-change: transform;
    transition: transform 200ms ease;

    &:hover {
      background: #e6e6ea
    }

    &:active {
      transform: scale(0.9);
    }
`;

export const MenuContainer = styled(Menu)`
    padding: 5px 0;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
`;