import styled from "styled-components";

export const RemoveCompanyContainer = styled.div`
  opacity: .7;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: -5px;
  transition: all .2s ease-out;
  transform: scale(0);
  cursor: pointer;
  width: 0%;

  &:hover {
    transform: scale(1) !important;
    opacity: 1;
    color: rgb(87,102,236) !important;
  }
`;

export const CompanyLabel = styled.div`
  font-weight: bold;
  color: #a7a7a7;
  background: rgb(245, 247, 248);
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease-out;
  height: 28px;
  white-space: nowrap;
  background: rgb(238,245,254);
  color: rgb(87,102,236);

  span {
    cursor: pointer;
  }

  &:hover {
    background: rgb(238,245,254);
    color: rgb(87,102,236);

    ${RemoveCompanyContainer} {
      color: #a7a7a7;
      display: flex;
      transform: scale(0.8);
      width: 100%;
    }
  }

  input {
    height: 25px;
    border: none;
    border-radius: 30px;
    margin-top: -2px;
    margin-bottom: -5px;
    border: 0px !important;
    align-self: flex-start;
  }
`;

export const Group = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.span`
  font-weight: bold;
`;

export const EmailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;