import React, { FC, useState } from 'react';
import { SettingPageHeader, Container, SettingPageDescription } from '../account/styles';
import UsageChart from 'components/usageChart';
import UsageTable from 'components/usageTable';

const UsageSettingsPage: FC = () => {
    const [period, setPeriod] = useState('month');
    const [type, setType] = useState('');

    return (
        <Container>
            <SettingPageHeader>Credits Usage</SettingPageHeader>
            <SettingPageDescription>Here you can check your credits usage history.</SettingPageDescription>
            
            <UsageChart period={period} setPeriod={setPeriod} type={type} setType={setType} />

            <br/> <br/> <br/>
            <SettingPageHeader>Operations history</SettingPageHeader>
            <SettingPageDescription>Here you can check your credits usage history.</SettingPageDescription>
            <UsageTable period={period} type={type} />            
        </Container>
    )
}

export default UsageSettingsPage;
