import { createSelector } from 'reselect';

const baseAutomationSelector = (state: any) => state?.application;

export const applicationDataSelector = createSelector(
 baseAutomationSelector,
  (application) => application?.data || [],
);

export const applicationsCountSelector = createSelector(
  baseAutomationSelector,
   (application) => application?.data?.length || 0,
 );

