import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/commonTable';
import { processingFileHeadersSelector, processingFilePreviewSelector } from 'data/selectors/bulkOperationsWizard';
import columns from './columns';

const ColumnsMatchingStep: FC = () => {
    const headers = useSelector(processingFileHeadersSelector);
    const previewData = useSelector(processingFilePreviewSelector);
    
    const data = useMemo(() => {
        return headers.map((header: string) => {
            const preview = previewData.map((item: any) => item?.[header]);
            return { column: header, preview }
        })
    }, [headers, previewData]);

    return <Table columns={columns} data={data} />
}

export default ColumnsMatchingStep;