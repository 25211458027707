import { ICredits } from 'ts/interfaces/credits/credits'
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ICredits = {
  credits: 0,
  totalCredits: 0,
};

const creditsReducer = (state = initialState, action: IReduxAction): ICredits | {} => {
  switch (action.type) {
    case EReduxTypes.GET_USER_CREDITS: {
      if (state.credits !== action?.payload?.credits) {
        return {
          credits: action?.payload?.credits || 0,
          totalCredits: action?.payload?.totalCredits || 0,
          lastRefreshedAt: action?.payload?.lastRefreshedAt
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default creditsReducer;