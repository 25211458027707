import { FC, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImage from 'assets/logo.svg';
import { Container, NavItemsContainer, NavItem, Logo, ProfileBarContainer, RefContainer, DesktopContainer, MobileMenuButtonContainer, MobileMenuContainer } from './styles';
import CreditsBar from 'components/creditsBar';
import ProfileBar from 'components/profileBar';
import useOnClickOutside from 'hooks/useOnClickOutside';
import MobileMenu from 'components/mobileMenu';
import { BiMenu } from 'react-icons/bi';

const Navigation: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <NavItem active={location?.pathname?.includes('search')} onClick={() => navigate('/search', { replace: true })}>
              Search
            </NavItem>
            <NavItem active={location?.pathname?.includes('verification')} onClick={() => navigate('/verification', { replace: true })} >Verification</NavItem>
            <NavItem active={location?.pathname?.includes('bulk-operations')} onClick={() => navigate('/bulk-operations', { replace: true })} >Bulk operations</NavItem>
            <NavItem active={location?.pathname?.includes('leads')} onClick={() => navigate('/leads', { replace: true })}>Leads</NavItem>
        </>
    )
}

const Header: FC = () => {
    const ref = useRef();
    const [mobileMenuVisible, toggleMobileMenuVisible] = useState(false);

    useOnClickOutside(ref, () => {
      toggleMobileMenuVisible(false);
    });
    
    return (
      <RefContainer ref={ref}>
        <Container>
            <NavItemsContainer>
              <Logo src={LogoImage} />
                <DesktopContainer>
                  <Navigation />
                  <ProfileBarContainer>
                    <CreditsBar />
                    <ProfileBar />
                  </ProfileBarContainer>
                </DesktopContainer>
                <MobileMenuContainer>
                  <ProfileBar mobile/>
                  <MobileMenuButtonContainer>
                    <BiMenu size={34} color="black" onClick={() => toggleMobileMenuVisible(!mobileMenuVisible)}/>
                  </MobileMenuButtonContainer>
                </MobileMenuContainer>
            </NavItemsContainer>
        </Container>
        {window.innerWidth <= 1249 && <MobileMenu visible={mobileMenuVisible} children={<Navigation />} />}
      </RefContainer>
    )
}

export default Header;