import styled from 'styled-components';

export const AlternativeEmailContainer = styled.div`
  margin-left: 23px;
  margin-top: 5px;
  display: flex;
  align-items: center;

  @media (max-width: 1249px) {
    flex-direction: column;
    margin: 0;

    input {
      margin: 10px 0;
    }

    button {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
`;

export const BillingContainer = styled.div`
  @media (max-width: 1249px) {
    display: flex;
    flex-direction: column;
  }
`;

export const EmailTypeContainer = styled.div`
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    @media (max-width: 1249px) {
      margin-top: 20px;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
