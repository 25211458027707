import { FC, useMemo, useState, useEffect, useCallback, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import Table from 'components/commonTable';
import { ILead } from 'ts/interfaces/leads/lead';
import { toggleModal } from 'data/actions/modals';
import { EModalTypes } from 'ts/enums/modal.types';
import { EDrawerTypes } from 'ts/enums/drawer.types';
import { toggleDrawer } from 'data/actions/drawer';
import { deleteLeads, searchLeads, setSelectedLeads } from 'data/actions/leads';
import { leadsDataSelector, leadsMetaSelector, selectedLeadsSelector } from 'data/selectors/leads';
import { SearchOutlined } from '@ant-design/icons';
import Pagination from 'components/pagination';
import Button from 'components/common/button';
import { ButtonHeight } from 'ts/enums/styles';
import generateExportLink from 'helpers/generateExportLink';
import LeadsEmptyState from 'components/emptyStates/leadsEmptyState';
import LeadsSearchEmptyState from 'components/emptyStates/leadsSearchEmptyState';

import { 
  ControlsBarContainer, 
  SearchInput, 
  ButtonContainer, 
  UploadIcon, 
  Container, 
  Title,
} from './styles';
import ActionsBar from './actionBar';
import LeadsTableColumns from './columns';


const LeadsTable: FC = () => {
  const dispatch = useDispatch();
  const leads = useSelector(leadsDataSelector);
  const selectedLeads = useSelector(selectedLeadsSelector);
  const { totalItems = 0, totalPages = 0, search: searchMeta } = useSelector(leadsMetaSelector);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [selectAll, toggleSelectAll] = useState<boolean>(false);

  const handleSelectAll = () => {
    toggleSelectAll(true);
    dispatch(setSelectedLeads(leads?.map((item: any) => item?._id)));
  }

  const handleDiscardAll = () => {
    toggleSelectAll(false);
    dispatch(setSelectedLeads([]));
  }

  useEffect(() => {
    dispatch(searchLeads({ pageNumber, pageSize }, search))
    dispatch(setSelectedLeads([]));
  }, [dispatch, pageNumber, pageSize, search]);

  const handleDelete = useCallback((ids: string[]) => {
    dispatch(
      toggleModal(
        EModalTypes.CONFIRMATION,
        true,
        { 
          title: 'Delete leads',
          description: `${ids.length || 'All'} lead${ids.length !== 1 ? 's' : ''} will be removed. Are you sure you want to do this?`,
          buttonAcceptText: 'Delete',
          buttonDeclineText: 'Cancel',
          acceptAction: () => {
            dispatch(deleteLeads(ids));
            dispatch(toggleModal(EModalTypes.CONFIRMATION, false));
          },
        },
      )
    )
  }, [dispatch])

  const columns = useMemo(() => {
    const handleEdit = (originalRow: ILead) => dispatch(toggleDrawer(true, EDrawerTypes.LEAD_INFO, originalRow))
    return LeadsTableColumns(handleEdit, handleDelete)
  }, [dispatch, handleDelete]);

  if (!leads?.length && !searchMeta) return <LeadsEmptyState />
  return (
    <Suspense fallback={<></>}>
      <Container>
        <Title>{totalItems} leads</Title>
        {(!!selectedLeads.length || selectAll)
          && (<ActionsBar deleteLeads={handleDelete} onSelectAll={handleSelectAll} onDiscardAll={handleDiscardAll} />)}
        <ButtonContainer>
          <Button
            disabled={!totalItems}
            size={ButtonHeight.MEDIUM}
            onClick={()=> window.open(generateExportLink(search, selectedLeads), '_blank')}
          >
            <UploadIcon>
              <AiOutlineCloudDownload />
            </UploadIcon>
            Export leads
          </Button>
        </ButtonContainer>
      </Container>
      <ControlsBarContainer>
        <SearchInput>
          <SearchOutlined />
          <input placeholder="Search contacts by name or email..." value={search} onChange={(e)=>
          setSearch(e?.target?.value)}/>
        </SearchInput>
        <Pagination totalItems={totalItems} pageNumber={pageNumber} pageSize={pageSize} onChangePage={setPageNumber}
          totalPages={totalPages} />
      </ControlsBarContainer>

      {!leads?.length && searchMeta
        ? <LeadsSearchEmptyState search={search} setSearch={setSearch} />
        : <Table columns={columns} data={leads} />
      }
    </Suspense>
  )
}

export default LeadsTable;