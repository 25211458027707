import styled, { keyframes, css } from 'styled-components';
import { headShake } from 'react-animations';

const headShakeAnimation = keyframes`${headShake}`;

export const SearchResultContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexStartCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const CompanyLogo = styled.img`
    width: 80px;
`;

export const NameDomainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const Name = styled.span`
    font-size: 18px;
`;

export const Domain = styled.span`
    color: #a7a7a7;
    font-size: 14px;
`;

export const EmailContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
` 

export const Email = styled.span < any >`
    font-size: 16px;
    cursor: pointer;

    ${({ copied }) => (copied && css`animation: 1s ${headShakeAnimation};`)};
`;

export const StatusPoint = styled.div`
    width: 10px;
    height: 10px;
    background-color: #4eb92d;
    border-radius: 50%;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
`;