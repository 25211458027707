import { Dispatch } from 'redux';
import { EModalTypes } from 'ts/enums/modal.types';
import { EReduxTypes } from 'ts/enums/redux.types';

export const toggleModal = (type: EModalTypes, visible: boolean, options?: any) => (dispatch: Dispatch) => {
  dispatch({
    type: EReduxTypes.TOGGLE_MODAL,
    data: {
      type,
      visible,
      options,
    }
  });
};
