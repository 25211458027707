import { jello } from "react-animations";
import styled, { keyframes } from "styled-components";

const jelloAnimation = keyframes`${jello}`;

export const SelectedCheckbox = styled.div`
  border-radius: 30px;
  width: 32px;
  background: rgb(72, 86, 216);
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  transition: transform 200ms ease;
  animation: 200ms ${jelloAnimation};

  &:hover, &:focus {
    background: rgb(112, 126, 249);
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    transform: scaleY(0.8) scaleX(0.4) rotate(45deg);
  }
`;

export const EmptyCheckbox = styled.div`
  border: 2px solid rgb(112, 126, 249);
  border-radius: 30px;
  width: 32px;
  background: white;

  &:active {
    transform: scale(0.8);
  }
`;

export const AvatarCheckboxContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
