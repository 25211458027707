import styled from 'styled-components';

export const MoreButtonContainer = styled.div`
  border-radius: 5px;
  width: 70px;
  gap: 2px;
  will-change: transform;
  transition: transform 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
    padding: 5px;

    :active {
      transform: scale(0.9);
    }
  }
`;

export const ControlsBarContainer = styled.div`
    background: white;
    padding: 20px 30px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;
export const SearchInput = styled.div`
    display: flex;
    align-items: center;
    font-size: 17px;

    input {
      border: none;
      margin-left: 10px;
      width: 300px;

      &:focus {
        outline: none;
      }
    }
`;

export const ButtonContainer = styled.div`
    width: 130px;
    margin-left: auto;
`;

export const ActionsContainer = styled.div`
  margin-left: 10px;
  display: flex;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
  }
`;

export const UploadIcon = styled.div`
    font-size: 20px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    border-top: 1px solid #e6eaee;
    padding-top: 20px;
    margin-top: 20px;
    justify-content: flex-start;
`;

export const Title = styled.span`
    color: #40413f;
    font-size: 20px;
    font-weight: bold;
`;

export const DeleteCell = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  will-change: transform;
  transition: transform 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;

  &:hover {
    background: #e6e6ea;
  }

  &:active {
    transform: scale(0.9);
  }
`;